import { ILangString } from "./lang-string";

export interface ILangStringEdit {
    code: string;
    useOneForAll: boolean;
    translations: ITranslation[];
}

export interface ITranslation { 
    language: string;
    translation: string; 
}

export function getLangString(str: ILangStringEdit) : ILangString | undefined {
    const trsl = str.useOneForAll ?
        str.translations[0] :
        str.translations.find(t => t.language === 'en');
    if (trsl) {
        return { code: str.code, text: trsl.translation };
    }

    return undefined;
}