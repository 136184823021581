<button *ngIf="isRootNode" mat-icon-button [matTooltip]="label" [matTooltipPosition]="'right'" color="primary" class="nav-button" [matMenuTriggerFor]="menu">
  <mat-icon>{{ icon }}</mat-icon>
</button>
<button *ngIf="!isRootNode" mat-menu-item [matMenuTriggerFor]="menu">
  {{ label }}
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let node of data">
    <button mat-menu-item >
      <app-menu [data]="sortedSubMenuItemList(node)" [label]="node.label" *ngIf="isExpandable(node); else menuItem"></app-menu>
    </button>
    <ng-template #menuItem>
      <button mat-menu-item [routerLink]="'/dashboard/' + node.dashboardName">{{ node.label }}</button>
    </ng-template>
  </ng-container>
</mat-menu>
