import { IErrorReport } from '@Models/error-report';
import { AppService } from '@Services/app.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.scss']
})
export class ErrorReportComponent implements OnInit, OnChanges {

  @Input() errorReportId?: number;
  @Output() onFormClosing: EventEmitter<void> = new EventEmitter();

  data!: IErrorReport;
  errorReport: any;
  supportLevel?: number;

  files: File[] = [];
  missing: string[] = [];
  saving = false;
  editing = false;

  constructor(
    private appService: AppService,
    private router: Router) {}

  ngOnInit(): void {
    this.setup();
    
    this.appService.getMe().subscribe({
      next: (res) => this.supportLevel = res.supportLevel
    });    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errorReportId'].previousValue !== changes['errorReportId'].currentValue) {
      this.setup();
    }
  }

  setup() {
    this.data = {
      summary: '',
      behavior: '',
      description: ''
    };
    
    if (this.errorReportId) {
      this.appService.getErrorReport(this.errorReportId).subscribe({
        next: (res) => {
          this.errorReport = res;
          this.data.summary = res.summary;
          this.data.behavior = res.behavior;
          this.data.description = res.description;
        },
        error: (err) => console.log(err)
      });
    }
  }

  fileSelected(fileEvent: any) {
    this.files.push(fileEvent.target.files);
  }

  onFileDropped(files: any) {
    this.files.push(files);
  }

  close() {
    this.onFormClosing.emit();
  }

  escalate() {
    if (this.errorReport.level > 1) {
      this.errorReport.level -= 1;
      this.editing = true;
    }
  }

  saveAndClose() {
    this.saving = true;
    this.appService.updateErrorReport(this.errorReportId!, this.errorReport).subscribe({
      next: (res) => {
        this.saving = true;
        this.onFormClosing.emit();
      },
      error: (err) => { 
          console.log(err);
          this.saving = false;
        }
    });
  }

  send() {
    this.saving = true;

    this.missing = [];
    if (this.data.summary.length === 0) {
      this.missing.push('summary');
    }
    if (this.data.description.length === 0) {
      this.missing.push('description');
    }
    if (this.data.behavior.length === 0) {
      this.missing.push('behavior');
    }

    if (this.missing.length > 0) {
      window.alert('Cannot Save!\nAll fields need to be filled out!');
      this.saving = false;
      return;
    }

    var additionalData = `Browser: ${window?.navigator.userAgent}\r\n`;
    additionalData += `Language: ${window?.navigator.language}\r\n`;
    additionalData += `Cookies: ${window?.navigator.cookieEnabled ? 'yes' : 'no'}\r\n`;

    const formData = new FormData();
    formData.append('summary', this.data.summary);
    formData.append('description', this.data.description);
    formData.append('behavior', this.data.behavior);
    formData.append('currentUrl', this.router.url);
    formData.append('additionalData', additionalData);

    if (this.files.length > 0) {
      this.files.forEach((file) => {
        formData.append('files', file);
      });
    }

    this.appService.sendErrorReport(formData).subscribe({
      next: () => {
        this.onFormClosing.emit();
        this.saving = false;
      },
      error: (err) => {
        console.log(err);
        this.saving = false;
      }
    });
  }

  get cannotEscalate() : boolean {
    return (this.supportLevel ?? 1000) >= this.errorReport.level;
  }
}
