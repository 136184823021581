<div class="header-area">
  <div>
    <h1>{{widgetSettings?.label}}</h1>
  </div>

  <div class="right-header-area">
    <!-- <button
      mat-icon-button
      class="copy-to-clipboard"
      (click)="refresh()">
      <mat-icon>update</mat-icon>
    </button> -->
    <button 
      *ngIf="formAllowAdd || parentHandlesAdd" 
      mat-icon-button 
      class="copy-to-clipboard" 
      (click)="newRecordForm()">
        <mat-icon>add</mat-icon>
    </button>
    <button 
      *ngIf="isAdmin && showWidgetEditForAdmin" 
      mat-icon-button 
      class="copy-to-clipboard" 
      (click)="openWidgetSettings()">
        <mat-icon>design_services</mat-icon>
    </button>
    <button 
      *ngIf="showCopyToClipboard"
      mat-icon-button 
      class="copy-to-clipboard" 
      (click)="copyToClipboard()">
        <mat-icon>content_copy</mat-icon>
    </button>
    <mat-form-field *ngIf="widgetSettings" appearance="standard" class="filter-area">
      <mat-label>Filter</mat-label>
      <input 
        matInput 
        type="text" 
        [(ngModel)]="filterValue" 
        (keyup)="filterChanged()">
      <button 
        *ngIf="filterValue" 
        matSuffix 
        mat-icon-button 
        aria-label="Clear" 
        (click)="clearFilter()" 
        class="clear-btn">
          <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<div class="table-area">
  <table #mainTable mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortStart="desc" [matSortActive]="orderBy" [matSortDirection]="orderDir">
    <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedColumns">
      <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header>{{column.label}}</th>
      <td mat-cell *matCellDef="let element" [class]="getTdClass(element)" (dblclick)="rowTriggered(element)">        
        <ng-container *ngIf="!element.groupBy">
          <ng-container *ngIf="column.linkedPages; else noLinkData">
            <div class="clickable-text" *ngIf="column.linkedPages.length == 1" (click)="linkedPageClicked(column.linkedPages[0], element, column.id)">
              {{element ? (element[column.id]) : ""}}
            </div>
            <div class="clickable-text" *ngIf="column.linkedPages.length > 1" [matMenuTriggerFor]="linkMenu">
              {{element ? (element[column.id]) : ""}}
            </div>
            <mat-menu #linkMenu="matMenu" >
              <ng-template matMenuContent let-name="linkMenu">
                <button mat-menu-item *ngFor="let link of column.linkedPages" (click)="linkedPageClicked(link, element, column.id)">
                  {{link.label}}
                </button>
              </ng-template>
            </mat-menu>
          </ng-container>
  
          <ng-template #noLinkData>
            <a *ngIf="column.type === 'url'; else notUrl" [attr.href]="element[column.id]">{{element[column.id]}}</a>
            <ng-template #notUrl>{{element ? (element[column.id] | dataFormat : column.type : column.customFormat) : ""}}</ng-template>
          </ng-template>
        </ng-container> 
        <ng-container *ngIf="element.groupBy">
          <div class="groupby-cell">
            {{ element ? (element[column.id]) : "" }}
          </div>
        </ng-container>       
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnArray(); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnArray();"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="1">
        <p *ngIf="dataSource.data.length == 0">No data</p>
      </td>
    </tr>
  </table>
</div>

<app-table-form 
  *ngIf="formData" 
  [formData]="formData"
  (closing)="formClosing($event)">
</app-table-form>

<app-error-report
  *ngIf="errorReportId"
  [errorReportId]="errorReportId"
  (onFormClosing)="errorReportId = undefined">  
</app-error-report>

<div *ngIf="isLoading">
  <mat-progress-spinner class="spinner" mode="indeterminate" value="0" [diameter]="70"></mat-progress-spinner>
</div>