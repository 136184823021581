import { Component } from '@angular/core';
import { IWidgetSetting } from '@Models/widget-settings';

@Component({
  selector: 'app-lang-string-admin',
  templateUrl: './lang-string-admin.component.html',
  styleUrls: ['./lang-string-admin.component.scss']
})
export class LangStringAdminComponent {
  public widgetSettings: IWidgetSetting = {
    id: 0,
    dataEndpoint: 'languagestrings/list',
    label: 'Language String',
    position: 1,
    rowSpan: 1,
    colSpan: 1,
    widgetType: 'table',
    widgetParameters: null
  };

  public code?: string;
  public reload = 0;

  public openRow(row: any) {
    this.code = row.code;
  }

  public formClosing(event: any) {
    this.code = undefined;
    this.reload = this.reload + 1;
  }
}
