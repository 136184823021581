<div class="weblink-widget">
  <div class="weblink-area">
    <div class="link-list">
      <ng-container *ngFor="let child of linkNodes">
        <a class="link" [href]="child.url" target="_blank" rel="noopener noreferrer">{{child.label}}</a>
      </ng-container>
    </div>
    <mat-accordion>
      <app-weblink-panel *ngFor="let link of folderNodes" [expanded]="expandAll" [weblink]="link"></app-weblink-panel>
    </mat-accordion>
  </div>
  <div class="filter-area">
    <mat-form-field *ngIf="visableList" appearance="standard" class="filter-area">
      <mat-label>Filter</mat-label>
      <input matInput type="text" [(ngModel)]="filterValue" (keyup)="filterChanged()">
      <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
