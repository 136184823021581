import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IQueryParameter } from '@Models/query-parameter';

@Component({
  selector: 'app-custom-query-paramlist',
  templateUrl: './custom-query-paramlist.component.html',
  styleUrls: ['./custom-query-paramlist.component.scss']
})
export class CustomQueryParamlistComponent implements AfterViewInit, OnInit {

  @Input() parameters!: IQueryParameter[]
  @Output() runClicked = new EventEmitter();

  public allowRun = false;

  ngOnInit() {
    this.setDefaults();
  }

  ngAfterViewInit() {
    this.valueChanged();
  }

  setDefaults() {
    this.parameters.forEach(p => {
      if (!p.value && p.defaultValue) {
        switch (p.type) {
          case 'checkbox':
            p.value = true;
            break;
          case 'number':
            p.value = Number.parseInt(p.defaultValue);
            break;
          case 'date':
            p.value = new Date(p.defaultValue);
            break;
          default:
            p.value = p.defaultValue;
        }
      }
    });
  }

  run() {
    this.runClicked.emit(true);
  }

  tryRun() {
    if (this.allowRun)
      this.run();
  }

  valueChanged() {
    let allSet = true;
    this.parameters.forEach(p => {
      if (p.type != "checkbox" && !p.optional && (p.value == null || p.value == '' || p.value == undefined))
        allSet = false;
    });

    this.allowRun = allSet;
  }
}
