import { Component, Input } from '@angular/core';
import { IWebLink } from '@Models/web-link';

@Component({
  selector: 'app-weblink-panel',
  templateUrl: './weblink-panel.component.html',
  styleUrls: ['./weblink-panel.component.scss']
})
export class WeblinkPanelComponent {

  @Input() weblink!: IWebLink;
  @Input() expanded!: boolean;

  constructor() { }

  get folderChildren(): IWebLink[] {
    if (this.weblink.children) {
      return this.weblink.children.filter(c => {
        if (c.children && !c.url)
          return true;
        return false;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    }

    return [];
  }

  get linkChildren(): IWebLink[] {
    if (this.weblink.children) {
      return this.weblink.children.filter(c => {
        if (c.url)
          return true;
        return false;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    }

    return [];
  }
}
