<div class="form-base">
    <mat-card class="card">
        <mat-card-title>
            {{title}}
        </mat-card-title>
        <mat-card-content class="card-content">
            <div class="search-input-area">
                <mat-form-field appearance="standard" class="search-field">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" [(ngModel)]="searchString" (keypress)="searchKeyPressed($event)">
                    <button *ngIf="searchString" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <button mat-raised-button color="basic" [disabled]="!searchString" (click)="search()">Search</button>
                  <mat-radio-group [(ngModel)]="viewType" class="viewType-radio">
                    <mat-radio-button value="tiles">Tiles</mat-radio-button>
                    <mat-radio-button value="list">List</mat-radio-button>
                  </mat-radio-group>
            </div>            
            <div *ngIf="searching && !searchResult" class="search-result-area">Nothing found...</div>
            <ng-container *ngIf="searching && searchResult">
                <div *ngIf="!viewTiles" class="search-result-area-list">
                    <div *ngFor="let res of searchResult" (click)="select(res)" class="search-list-item">
                        {{res.title}}.{{res.ending}}
                    </div>
                </div>
                <div *ngIf="viewTiles" class="search-result-area-tiles">
                    <div *ngFor="let res of searchResult" (click)="select(res)" class="search-tile-item">
                        <img *ngIf="res.previewUrl" [src]="res.previewUrl">
                        <img *ngIf="!res.previewUrl" src="http://www.logicnavigator.com/uploads/8/3/2/7/83271518/editor/fileimage_10.png">
                        <div>{{res.title}}.{{res.ending}}</div>
                    </div>
                </div>
            </ng-container>
        </mat-card-content>
        <mat-card-actions class="card-actions">
            <button mat-raised-button (click)="empty()">Assign Empty</button>
            <button mat-raised-button (click)="cancel()">Cancel</button>
            <button mat-raised-button (click)="createNew()">
                Create new
            </button>
        </mat-card-actions>
    </mat-card>
</div>

<resource-form
    *ngIf="resourceInfo"
    [resourceInfo]="resourceInfo"
    (onFormClosing)="onNewResourceLinkFormClosing($event)">
</resource-form>
