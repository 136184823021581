import { Component, Input, OnInit } from '@angular/core';
import { IMenuSetup } from '@Models/menu-setup';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input() data: IMenuSetup[] = [];
  @Input() icon!: string;
  @Input() label = "Unknown";
  @Input() isRootNode = false;

  constructor() { }

  isExpandable(node: IMenuSetup): boolean {
    return node.subMenuItemList != null && node.subMenuItemList.length > 0;
  }

  sortedSubMenuItemList(node: IMenuSetup): IMenuSetup[] {
    if (node.subMenuItemList) {
      return node.subMenuItemList.sort((a, b) => a.position - b.position);
    }
    return [];
  }
}
