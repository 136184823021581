<app-table-widget
    class="widget"
    [widget]="widgetSettings"
    [showWidgetEditForAdmin]="false"
    [showCopyToClipboard]="false"
    [parentHandlesRowTriggered]="true"
    [parentHandlesAdd]="true"
    [triggerDataReload]="reload"
    (onRowTriggered)="openRow($event)"
    (onAdd)="newRecord()">
</app-table-widget>

<resource-form
    *ngIf="resourceInfo"
    [resourceInfo]="resourceInfo"
    (onFormClosing)="onFormClosing($event)">
</resource-form>
