import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITableList } from '@Models/table-list';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  constructor(private http: HttpClient) {}

  getAllLists(): Observable<ITableList[]> {
    let url = environment.apiUrl + 'weblists';
    return this.http.get<ITableList[]>(url);
  }

  getLists(table: string): Observable<ITableList[]> {
    let url = environment.apiUrl + 'weblists/' + table;
    return this.http.get<ITableList[]>(url);
  }

  getList(table: string, id: number): Observable<ITableList> {
    let url = `${environment.apiUrl}weblists/${table}/${id}`;
    return this.http.get<ITableList>(url);
  }

  createList(list: ITableList) {
    let url = environment.apiUrl + 'weblists';
    return this.http.post(url, list);
  }

  appendRecordsToList(id: number, records: number[]) {
    let url = `${environment.apiUrl}weblists/append/${id}`;
    return this.http.patch(url, records);
  }

  removeRecordsFromList(id: number, records: number[]) {
    let url = `${environment.apiUrl}weblists/remove/${id}`;
    return this.http.patch(url, records);
  }

  changeColumnsForList(id: number, columns: string) {
    let url = `${environment.apiUrl}weblists/columns/${id}`;
    return this.http.patch(url, { newColumns: columns });
  }

  deleteList(id: number) {
    let url = `${environment.apiUrl}weblists/${id}`;
    return this.http.delete(url);
  }

  getArticleList(id: number): Observable<ITableList> {
    let url = `${environment.apiUrl}weblists/articlenumbers/${id}`;
    return this.http.get<ITableList>(url);
  }
}
