import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResourceLink } from "@Models/resource-link";
import { IResourceSearchResult } from "@Models/resource-search-result";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class ResourceService {
    endpoint = 'resourcelinks/' 

    constructor(private http: HttpClient) {}

    get(id: number) : Observable<ResourceLink> {
        const url = `${environment.apiUrl}${this.endpoint}${id}`;        
        return this.http.get<ResourceLink>(url);
    }

    getNewTemplate() : Observable<ResourceLink> {
      const url = `${environment.apiUrl}${this.endpoint}default`;
      return this.http.get<ResourceLink>(url);
    }

    search(searchString: string, images?: boolean, ending?: string) : Observable<IResourceSearchResult[]> {
      const cleanStr = encodeURIComponent(searchString);
      let url = `${environment.apiUrl}${this.endpoint}search?searchstring=${cleanStr}`;
      if (images) {
        url += `&images=true`
      }
      if (ending) {
        url += `&ending=${ending}`
      }

      return this.http.get<IResourceSearchResult[]>(url);
    }

    getLatest() : Observable<IResourceSearchResult[]> {
      const url = `${environment.apiUrl}${this.endpoint}latest`;
      return this.http.get<IResourceSearchResult[]>(url);
    }

    post(formData: FormData) : Observable<ResourceLink> {
        const url = environment.apiUrl + this.endpoint;
        return this.http.post<ResourceLink>(url, formData);
      }

    patch(id: number, formData: FormData) : Observable<ResourceLink> {
      const url = `${environment.apiUrl}${this.endpoint}${id}`;
      return this.http.patch<ResourceLink>(url, formData);
    }
}