
  <div mat-dialog-title>
    <h1>{{data.title}}</h1>
  </div>
  <div mat-dialog-content>
    <mat-form-field appearance="standard">
      <mat-label>Name</mat-label>
      <input #webListName matInput [(ngModel)]="data.list.name" type="text" cdkFocusInitial>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="data.list.private">Private</mat-checkbox>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancel</button>
    <button *ngIf="data.list.name" mat-button [mat-dialog-close]="data.list">Save</button>
  </div>
