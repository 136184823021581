<app-table-widget
    class="widget"
    [widget]="widgetSettings"
    [showWidgetEditForAdmin]="false"
    [showCopyToClipboard]="false"
    [parentHandlesRowTriggered]="true"
    [triggerDataReload]="reload"
    (onRowTriggered)="openRow($event)">
</app-table-widget>

<lang-form    
    *ngIf="code"
    [stringCode]="code!"
    (closing)="formClosing($event)">
</lang-form>