import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ILangString } from '@Models/lang-string';
import { Observable, of } from 'rxjs';
import { ILangStringEdit } from '@Models/lang-string-edit';
import { ILanguage } from '@Models/language';

@Injectable({
  providedIn: 'root'
})
export class LangService {
    private langEndpoint = 'languagestrings/'
  constructor(private http: HttpClient) {} 

  getLanguages() : Observable<ILanguage[]> {
    const url = `${environment.apiUrl}${this.langEndpoint}languages`;
    return this.http.get<ILanguage[]>(url);
  }

  getText(code: string, lang: string) : Observable<string> {
    const url = `${environment.apiUrl}${this.langEndpoint}?lang=${lang}&code=${code}`;
    return this.http.get<string>(url);
  }

  search(searchString?: string, codePrefix?: string) : Observable<ILangString[]> {
    
    let url = `${environment.apiUrl}${this.langEndpoint}search`;
    if (searchString) {
      const cleanStr = encodeURIComponent(searchString);
      url += `?searchstring=${cleanStr}`;
      if (codePrefix)
        url += `&codePrefix=${codePrefix}`;      
    }
    else if (codePrefix)
      url += `?codePrefix=${codePrefix}`

    return this.http.get<ILangString[]>(url);
  }

  loadForEdit(code: string) : Observable<ILangStringEdit> {
    const url = `${environment.apiUrl}${this.langEndpoint}edit/${code}`;
    return this.http.get<ILangStringEdit>(url);
  }

  post(langString: ILangStringEdit) : Observable<ILangStringEdit> {
    const url = `${environment.apiUrl}${this.langEndpoint}`;
    return this.http.post<ILangStringEdit>(url, langString);
  }

  put(langString: ILangStringEdit) : Observable<ILangStringEdit> {
    const url = `${environment.apiUrl}${this.langEndpoint}`;
    return this.http.put<ILangStringEdit>(url, langString);
  }
}