import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IEditMemo } from '../Models/edit-memo';

@Component({
  selector: 'memo-card',
  templateUrl: './memo-card.component.html',
  styleUrls: ['./memo-card.component.scss']
})
export class MemoCardComponent implements OnChanges {

  private originalValue!: string;

  @Input() editMemo: IEditMemo | null = null;
  @Output() dataChanged: EventEmitter<IEditMemo> = new EventEmitter<IEditMemo>();

  ngOnChanges(): void {
    this.originalValue = this.editMemo!.data[this.editMemo!.column.id];  
  }

  close(): void {
    if (this.originalValue !== this.editMemo!.data[this.editMemo!.column.id]) {
      this.dataChanged.emit(this.editMemo!);
    }
    
    this.editMemo = null;
  }

  formatJson() {
    const json = JSON.parse(this.editMemo!.data[this.editMemo!.column.id])
    if (json) {
      const value = JSON.stringify(json, null, 3);
      this.editMemo!.data[this.editMemo!.column.id] = value
    }    
  }

  formatSql() {
    if (!this.editMemo?.data[this.editMemo!.column.id])
      return;
    let statement = this.editMemo!.data[this.editMemo!.column.id];    
    this.editMemo!.data[this.editMemo!.column.id] = this.pretifySql(statement);
  }

  pretifySql(statement: string): string {
    this.mainSqlWord.forEach(keyWord => {
      const regEx = new RegExp(` ${keyWord}`, 'ig');
      statement = statement.replace(regEx, `\n${keyWord}`)
    });

    statement = statement.replace(new RegExp("(?<!left)(?<!right) join ", 'ig'), "\nJOIN ");
    statement = statement.replace(new RegExp(" union ", 'ig'), "\nUNION\n ");
    // statement = statement.replace(/ +/, ' ');
    // statement = statement.replace(/,\S/, ", ");

    this.sqlKeyWords.forEach(keyWord => {
      const regEx = new RegExp(keyWord, 'ig');
      statement = statement.replace(regEx, keyWord.toUpperCase())
    });

    return statement;
  }

  private sqlKeyWords = [
    'select ', 'from ', 'where ', 'order by ', 'asc ', 'group by ', 'union ', 'join ', 'left join ', 'right join ', 'in '
  ];

  private mainSqlWord = [
    'from ', "where ", 'left join ', "right join "
  ];
}