import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WidgetService } from '@Services/widget.service';
import { IDashboardSettings } from '@Models/dashboard-settings';
import { IQueryParameter } from '@Models/query-parameter';
import { IWidgetSetting } from '@Models/widget-settings';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { IFormData } from '@Models/form-data';

@Component({
  selector: 'app-widget-page',
  templateUrl: './widget-page.component.html',
  styleUrls: ['./widget-page.component.scss']
})
export class WidgetPageComponent implements AfterViewInit, OnDestroy {

  @Input() 
  get board(): string { return this.dashboard};
  set board(b: string) {
    this.parameters = [];
    this.customQueryString = '';
    this.dashboard = b;
    this.getPageSetup();
  }

  public rows!: number;
  public cols!: number;
  public widgetList!: IWidgetSetting[];
  public tiles!: number;
  public parameters!: IQueryParameter[];
  public customQueryString!: string;

  private dashboard!: string;
  private subs = new Subscription();

  public formData?: IFormData;

  constructor(
    private widgetService: WidgetService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title) { }


  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.subs.add(this.route.queryParams.subscribe({
      next: (params: Params) => {
        const id = this.route.snapshot.paramMap.get("id");
        if (this.parameters && this.dashboard === id) {
          let allSet = true;
          let queryString = '';
          this.parameters.forEach(p => {
            const value = params[p.name] ?? p.defaultValue;
            if (value || p.type == 'checkbox') {
              p.value = value ?? false;
              queryString += `${p.name}=${p.value ?? false}&`;
            }
            else
              allSet = false;
          });

          if (allSet) {
            this.customQueryString = queryString.substring(0, queryString.length - 1);
          }
        }
      }
    }));
  }

  getPageSetup() {
    if (this.board) {
      this.widgetService.getWidgetPageSetup(this.board).subscribe({
        next: (res: IDashboardSettings) => {
          this.rows = res.rows;
          this.cols = res.cols;
          this.widgetList = res.webWidgetSettings?.sort((a, b) => a.position - b.position);
          this.tiles = this.widgetList ? this.widgetList.length : 0;
          this.parameters = res.parameters;
          let queryString = '';
          if (this.parameters) {
            let allSet = true;
            this.parameters.forEach(p => {
              const value = this.route.snapshot.queryParamMap.get(p.name) ?? p.defaultValue;
              if (value || p.type == 'checkbox') {
                p.value = value;
                queryString += `${p.name}=${p.value ?? false}&`;
              }
              else
                allSet = false;
            });

            if (allSet) {
              this.customQueryString = queryString.substring(0, queryString.length - 1);
            }
          }

          this.titleService.setTitle(environment.AppName + ' - ' + this.board);
        },
        error: err => console.log(err)
      });
    }
  }

  getWidget(c: number): string {
    if (c >= this.widgetList.length)
      return '??';

    return this.widgetList[c].widgetType;
  }

  getRowspan(i: number) {
    if (i == 0)
      return 2;
    return 1;
  }

  getColspan(i: number) {
    return 1;
  }

  get items(): any[] {
    return Array(this.tiles).fill(1);
  }

  runCustomQueries() {
    let allSet = true;
    let params: any = {};
    if (this.parameters?.length > 0) {
      this.parameters.forEach(p => {
        if (p.type != 'checkbox' && (p.value == null || p.value == '')) {
          allSet = false;
        } else {
          params[p.name] = p.value;
        }
      });
    }

    if (allSet) {
      this.router.navigate([`/dashboard/${this.board}`], { queryParams: params });
    }
  }

  public openWidgetSettings(widgetId: number) {
    this.formData = {
      name: 'Edit widget',
      rowId: widgetId,
      table: "WebWidgetSetting",
  };
  }

  public formClosing() {
    this.formData = undefined;
  }
}
