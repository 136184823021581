<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon style="margin-right: 20px;">folder</mat-icon>
      {{weblink.label}}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-accordion>
    <app-weblink-panel *ngFor="let child of folderChildren" [expanded]="expanded" [weblink]="child"></app-weblink-panel>
  </mat-accordion>

  <div class="link-list">
    <ng-container *ngFor="let child of linkChildren">
      <a class="link" [href]="child.url" target="_blank" rel="noopener noreferrer">{{child.label}}</a>
    </ng-container>
  </div>
</mat-expansion-panel>
