<div class="header">
  <h1 *ngIf="!selectedQuery">Select Custom Query</h1>
  <div *ngIf="selectedQuery" class="menu-area">
    <h3>{{selectedQuery.label}}</h3>
    <button mat-button color="Primary" (click)="unselect()">Back</button>
  </div>
  <div class="menu-area" *ngIf="selectedQuery">
    <app-custom-query-paramlist
      [parameters]="selectedQuery.parameters"
      (runClicked)="paramChanged()">
    </app-custom-query-paramlist>
    <div class="filter-area" *ngIf="dataSource">
      <mat-form-field appearance="standard" class="filter-area">
        <mat-label>Filter</mat-label>
        <input matInput type="text" [(ngModel)]="filterValue" (keyup)="filterChanged()">
        <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
<div *ngIf="!selectedQuery">
  <ng-container *ngFor="let query of queryList">
    <div class="query-row" (click)="select(query)">
      {{query.label}}
    </div>
  </ng-container>
</div>
<div *ngIf="dataSource">
  <table #mainTable mat-table [dataSource]="dataSource" matSort matSortStart="desc" [matSortActive]="orderBy" [matSortDirection]="orderDir">
    <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedColumns">
      <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header>{{column.label}}</th>
      <td mat-cell *matCellDef="let element">
          {{element ? (element[column.id] | dataFormat : column.type : column.customFormat) : ""}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnArray(); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnArray();"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="1">
        <p *ngIf="!dataSource.data  || dataSource.data.length == 0">No data</p>
      </td>
    </tr>
  </table>
</div>
