import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ITableColumn } from "@Models/table-column";

export interface IColumnSetupInput {
  columnData: ITableColumn[];
  stickyColumns: number;
  isList: boolean;
}

export interface IColumnSetupOutput {
  doReset: boolean;
  saveColumnsToList: boolean;
  columnData: ITableColumn[];
  stickyColumns: number;
}

@Component({
  selector: 'app-column-setup',
  templateUrl: './column-setup.component.html',
  styleUrls: ['./column-setup.component.scss']
})
export class ColumnSetupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ColumnSetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IColumnSetupInput) { }

  public columnDataSource!: MatTableDataSource<any>;

  public columnTableColumns = ['sticky', 'label', 'visible', 'actions'];
  public maxColumnPosition = 1;
  public minColumnPosition = 0;
  public oldSticky: number = 0;

  public output!: IColumnSetupOutput;

  ngOnInit() {
    if (this.data) {
      this.data.columnData.sort((a, b) => a.position - b.position);
      this.maxColumnPosition = this.data.columnData[this.data.columnData.length - 1].position;
      this.minColumnPosition = this.data.columnData[0].position;
      this.columnDataSource = new MatTableDataSource(this.data.columnData);
    }

    this.oldSticky = this.data.stickyColumns;
    this.output = {
      doReset: false,
      columnData: this.data.columnData,
      stickyColumns: this.data.stickyColumns,
      saveColumnsToList: false,
    };
  }

  cancel() {
    this.dialogRef.close();
  }

  resetColumns() {
    this.output.doReset = true;
    this.dialogRef.close(this.output);
  }

  saveListColumnSetup() {
    this.output.saveColumnsToList = true;
    this.dialogRef.close(this.output);
  }

  accept() {
    this.dialogRef.close(this.output)
  }

  moveColumnUp(element: ITableColumn) {
    let up = this.data.columnData.find(c => c.position == element.position - 1);
    element.position -= 1;
    if (up)
      up.position += 1;
    this.data.columnData.sort((a, b) => a.position - b.position);
    this.columnDataSource.data = [... this.data.columnData];
  }

  moveColumnDown(element: any) {
    let down = this.data.columnData.find(c => c.position == element.position + 1);
    element.position += 1;
    if (down)
      down.position -= 1;
    this.data.columnData.sort((a, b) => a.position - b.position);
    this.columnDataSource.data = [... this.data.columnData];
  }

  radioSelected(i: number) {
    if (i === this.oldSticky){
      setTimeout(() => this.output.stickyColumns = 0, 5);
      this.oldSticky = 0;
    }
    else
      this.oldSticky = i;
  }
}
