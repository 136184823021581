import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ILangString } from '@Models/lang-string';
import { LangService } from '@Services/lang.service';

@Component({
  selector: 'lang-search',
  templateUrl: './lang-search.component.html',
  styleUrls: ['./lang-search.component.scss']
})
export class LangSearchComponent implements OnChanges {
  public searchResult: ILangString[] | undefined;
  public searchString: string = "";
  public searching = false;
  public state = { searching: true, newRecord: false, sameCodePrefix: true }
  public newText = "";

  @Input() title: string = "";
  @Input() code: string = "MMX";
  @Output() closing: EventEmitter<ILangString | undefined> = new EventEmitter<ILangString | undefined>

  constructor(private langService: LangService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['code'].currentValue !== changes['code'].previousValue) {
      this.searchString = '';
      this.search();

      setTimeout(() => {
        const area = document.getElementById('search-input');
        area?.focus();
      }, 100);
    }
  }

  searchKeyPressed($event: any) {
    if ($event.key === "Enter") {
      this.search();
    }
  }

  search() {
    const codePrefix = this.state.sameCodePrefix ? this.code : undefined;
    this.langService.search(this.searchString, codePrefix).subscribe({
      next: (res: ILangString[]) => {
        this.searching = true;
        this.searchResult = res?.sort((a,b) => a.text.localeCompare(b.text));
      },
      error: (err) => console.log(err)
    })
  }

  clearSearch() {
    this.searchString = "";
    this.searchResult = undefined;
  }

  select(langStr: ILangString) {
    this.closing.emit(langStr);
    this.title = "";
  }

  createNew() {
    this.state.searching = false;
  }

  newFormClosing(str: ILangString | undefined) {
    if (str) {
      this.closing.emit(str);
    } else {
      this.state.searching = true;
    }
  }

  empty() {
    this.closing.emit({ code: '', text: '' });
  }

  cancel() {
    this.closing.emit(undefined);
  }
}
