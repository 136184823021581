<div *ngIf="editMemo" class="memo-background">
    <mat-card class="memo-card">
      <mat-card-header>
        <h1>{{editMemo.column.label}}</h1>
      </mat-card-header>
      <mat-card-content>
        <textarea
          id="memo-area"
          class="memo-input"
          [readonly]="!editMemo.column.allowEdit"
          cols="160"
          rows="40"
          spellcheck="false"
          [(ngModel)]="editMemo.data[editMemo.column.id]"
          wrap="off"></textarea>
      </mat-card-content>
      <mat-card-footer class="memo-footer">
        <div class="format-area">
          <ng-container *ngIf="editMemo.column.allowEdit">
            <button mat-button (click)="formatSql()">Format Sql</button>
            <button mat-button (click)="formatJson()">Format Json</button>
          </ng-container>
        </div>
        <button mat-button (click)="close()">Close</button>
      </mat-card-footer>
    </mat-card>
  </div>