export const environment = {
  envName: "Test",
  AppName: "SSoTest",
  production: false,
  apiUrl: 'https://unisynk-test-webapi.azurewebsites.net/api/',
  eventEndpoint: '',
  jwtValidationUrl: 'https://unisynk-test-webapi.azurewebsites.net/access/validate',
  jwtRefreshUrl: 'https://unisynk-test-webapi.azurewebsites.net/access/refresh',
  hostedDomain: 'uncr.se',
  googleClientId: '813872934063-9a93e9lfaidqhkvps107m4n74ejgnl8d.apps.googleusercontent.com',
  appInsightConnectionString: 'InstrumentationKey=55d0ba99-3560-4228-8daf-f5f38233d5b8;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=28517799-7f51-411c-821d-26abd89bdc0f'
};
