import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ITableList } from '@Models/table-list';
import { EditListComponent } from './edit-list/edit-list.component';
import { ListService } from '@Services/list.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-table-list-view',
  templateUrl: './table-list-view.component.html',
  styleUrls: ['./table-list-view.component.scss']
})
export class TableListViewComponent implements OnInit {

  subs = new Subscription();
  private dataArray!: ITableList[];
  public dataSource!: MatTableDataSource<any>;
  public tableColumns = ['table', 'name', 'private', 'actions'];
  public filterValue = '';

  @ViewChild(MatSort) sort = new MatSort();

  constructor(private router: Router,
    private listService: ListService,
    private dialog: MatDialog,
    private titleService: Title) { }

  ngOnInit() {    
    this.titleService.setTitle(environment.AppName + ' - Manage Lists');
    this.listService.getAllLists().subscribe({
      next: (res: ITableList[]) => {
        this.dataArray = res;
        this.dataArray.sort((a,b) => a.table.localeCompare(b.table));
        this.dataSource = new MatTableDataSource(this.dataArray);
        this.dataSource.sort = this.sort;
      },
      error: err => console.log(err)
    });
  }

  open(element: ITableList) {
    let params: any = { list: element.id };
    this.router.navigate(['/tables', element.table], { queryParams: params })
  }

  edit(element: ITableList) {
    const dialogRef = this.dialog.open(EditListComponent, {
      data: {
        title: 'Edit List',
        list: element
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      /// SAAAAAVE
    });
  }

  delete(element: ITableList) {
    const ind = this.dataArray.indexOf(element);
    if (ind < 0)
      return;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delet List',
        message: `Are you sure you want to delete list "${element.name}"?`
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.listService.deleteList(element.id).subscribe({
          next: () => {
            this.dataArray.splice(ind, 1);
            this.dataSource = new MatTableDataSource(this.dataArray);
            this.dataSource.sort = this.sort;
          },
          error: err => console.log(err)
        });
      }
    })
  }

  private timer: any;
  filterChanged() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {this.applyFilter() }, 800);
  }

  clearFilter() {
    this.filterValue = '';
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }
}
