<div *ngIf="secondary" class="secondary-table-title">
    {{secondary.label}}
</div>
<div class="secondary-table-area">
    <table mat-table *ngIf="secondary"  [dataSource]="secondary.dataSource" class="secondary-table" matSort>
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef class="edit-button-header-cell">
                <button *ngIf="secondary.allowAdd" mat-icon-button (click)="newRecord()">
                    <mat-icon>add</mat-icon>
                </button>
            </th>
            <th mat-cell *matCellDef="let row" class="edit-button-row-cell">
                <button mat-icon-button [matMenuTriggerFor]="rowMenu">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #rowMenu="matMenu">
                    <button *ngIf="secondary.useForm" mat-menu-item (click)="openRecord(row)">Edit row</button>
                    <button *ngIf="secondary.allowAdd" mat-menu-item (click)="copyRecord(row)">Copy row</button>
                    <button *ngIf="!secondary.useForm && secondary.allowDelete" mat-menu-item (click)="deleteRecord(row)">Delete row</button>
                </mat-menu>
            </th>
        </ng-container>

        <ng-container [matColumnDef]="column.id" *ngFor="let column of secondary.columns; let i = index">
            <th mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>
                {{!secondary.useForm && column.required ? column.label + '*' : column.label}}
            </th>

            <td mat-cell *matCellDef="let element" [class]="rowCellClass(column, element)" (dblclick)="openRecord(element)">
                <ng-container *ngIf="!secondary.allowUpdate || secondary.useForm; else inlineEdit" [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="'choice'">
                        {{ getChoiceLabel(element[column.id], column) }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'url'">
                        <a [attr.href]="element[column.id]" target="_blank">{{element[column.id]}}</a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'refUrl'">
                        <a *ngIf="column.refId" [attr.href]="element[column.refId]" target="_blank">{{element[column.id]}}</a>
                        <a *ngIf="!column.refId">{{element[column.id]}}</a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'imgUrl'">
                        <img 
                            *ngIf="element[column.id]" 
                            class="row-image"
                            [src]="element[column.id]" />
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{element ? (element[column.id] | dataFormat : column.type : column.customFormat) : ""}}
                    </ng-container>                                        
                </ng-container>
                <ng-template #inlineEdit>
                    <ng-container [ngSwitch]="column.type">
                        <ng-container *ngSwitchCase="'choice'">
                            <mat-form-field>
                                <mat-label><a *ngIf="isMissingValue(element, column.id)">(Required)</a></mat-label>
                                <mat-select [(ngModel)]="element[column.id]" (selectionChange)="formDataChanged(element, column)">
                                <mat-option *ngFor="let val of column.possibleValues" [value]="val.value">{{val.label}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngSwitchCase="'checkbox'">
                            <mat-checkbox [(ngModel)]="element[column.id]" (change)="formDataChanged(element, column)"></mat-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchCase="'textarea'">
                            <div class="textarea-field">
                                {{element ? element[column.id] : ""}}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'langStr'">
                            <div class="flex-row">
                                <mat-icon
                                    *ngIf="element[column.id]" 
                                    class="field-icon"
                                    (click)="openLangString(column, element)" >edit</mat-icon>
                                <mat-icon 
                                    class="field-icon"
                                    (click)="openLangStringSearch(column, element)">translate</mat-icon>
                                {{ getLangStringText(element[column.id]) }}
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'resLink'">
                            <div class="flex-row-split" appDragDropFileUpload (fileDropped)="createResLink(column, element, $event)">
                                <div class="flex-row">
                                    <mat-icon *ngIf="element[column.id]" (click)="openResLink(column, element)" class="res-icon">edit</mat-icon>
                                    <mat-icon *ngIf="!element[column.id]" (click)="createResLink(column, element)" class="res-icon">upload</mat-icon>
                                    <mat-icon (click)="openResLinkSearch(column, element)" class="res-icon">plagiarism</mat-icon>
                                    <a mat-icon-button *ngIf="element[column.id]" [href]="getResLinkOriginalUrl(element[column.id])" target="_blank" class="res-icon" style="width:min-content"><mat-icon>download</mat-icon></a>
                                    <a class="resLinkTitle">{{ getResLinkTitle(element[column.id]) }}</a>
                                </div>                                
                                <img 
                                    *ngIf="element[column.id] && hasResLinkThumbnail(element[column.id])" 
                                    class="row-image-link" 
                                    (click)="showResLinkThumbnail(element[column.id])"
                                    [src]="getResLinkPreviewUrl(element[column.id])" />
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <mat-form-field class="form-field">
                                <input [type]="column.type" matInput [(ngModel)]="element[column.id]" (change)="formDataChanged(element, column)">
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="columnArray; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnArray;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="1">
                <p>No data</p>
            </td>
        </tr>
    </table>
</div>
<div 
    *ngIf="showThumbnailUrl" 
    class="big-image-div"
    (click)="showResLinkThumbnail()">
        <img
            class="big-image"            
            [src]="showThumbnailUrl" />
</div>