<div class="background-area">
    <mat-card class="card">
        <mat-card-title class="title-area">Language String</mat-card-title>
        <mat-card-subtitle>{{code}}</mat-card-subtitle>
        <mat-card-content *ngIf="selectedLanguage && langString" class="content-area">
            <mat-checkbox
                *ngIf="langString"
                [(ngModel)]="langString.useOneForAll"
                (change)="useOneChanged()">
                Group by writing system
            </mat-checkbox>
            <div *ngIf="!langString?.useOneForAll" class="tab-button-area">
                <button 
                    *ngFor="let lang of languages"
                    [class]="lang === selectedLanguage ? 'tab-button-active' : 'tab-button'"
                    (click)="changeLanguage(lang)">{{ lang.name }}</button>
            </div>
            <div class="textarea-container">
                <textarea 
                    *ngIf="currentTranslation"
                    id="memo-area"
                    class="memo-input"
                    [lang]="selectedLanguage.short"
                    [(ngModel)]="currentTranslation.translation"
                    (ngModelChange)="onModelChange()"
                    wrap="off"></textarea>
            </div>
        </mat-card-content>
        <mat-card-actions class="actions-area">
            <div class="left-action-area">
                <!-- <button 
                    *ngIf="langString"
                    mat-button 
                    [disabled]="langString.useOneForAll"
                    (click)="translateAll()">Translate All</button> -->
            </div>            
            <div class="right-action-area">
                <button 
                    mat-button 
                    [disabled]="!editing" 
                    (click)="save()">Save</button>
                <button 
                    mat-button 
                    [disabled]="!editing" 
                    (click)="save(true)">Save & Close</button>
                <button 
                    mat-button 
                    (click)="close()">{{ newRecord ? 'Cancel' : 'Close'}}</button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>