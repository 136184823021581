import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ILangString } from '@Models/lang-string';
import { IResourceSearchResult } from '@Models/resource-search-result';
import { ISecondaryData } from '@Models/secondary';
import { ITableColumn } from '@Models/table-column';
import { TableService } from '@Services/table.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { IEditMemo } from '../Models/edit-memo';
import { ITableData } from '@Models/table-data';
import { ResourceLink } from '@Models/resource-link';
import { IFormData, ISubFormData } from '@Models/form-data';
import { FormToOpen } from '../form-secondary/form-secondary.component';
import { IResourceFormInfo } from '@Models/resource-form-info';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss']
})
export class TableFormComponent {

  @Input() 
  get formData(): IFormData | undefined {
    return this._formData;
  }
  set formData(data: IFormData | undefined) {
    this._formData = data;
    this.initForm();
  }
  
  @Output() closing = new EventEmitter<any>();

  public _formData!: IFormData | undefined;
  public tableData: ITableData | undefined;
  public dataRow: any | undefined;
  public columns: ITableColumn[] | undefined;
  
  public fieldTabGroups: IFormTab[] = [];
  public selectedFieldTabGroup!: { tabName: string, sections: any[] };
  public selectedTabGroup!: string;
  public secondaryTabGroups: string[] = [];
  public actionButtons?: { label: string, actionUrl: string }[];

  public subFormData!: ISubFormData | undefined;
  public editMemo: IFormEditMemo | undefined = undefined;
  public editLangString: { stringCode: string, row: any, columnId: string, secondary?: any } | undefined;
  public editResLink: { resInfo: IResourceFormInfo, row: any, column: ITableColumn, secondary?: any } | undefined;
  public searchLangString: { column: ITableColumn, row: any, secondary?: any } | undefined;
  public searchResLink: { column: ITableColumn, row: any, secondary?: any } | undefined;

  private primaryFieldMaxRows = 1;
  private changedFormData!: any;
  public editing = false;
  public actionButtonsDisabled = false;

  constructor(
    private tableService: TableService,
    private dialog: MatDialog) { } 

  loadData() {
    if (this._formData) {
      this.tableService.get(this._formData.table, this._formData?.rowId).subscribe({
        next: (res: ITableData) => {
          if (res.rows?.length === 1) {
            this.tableData = res;
            this.dataRow = res.rows[0];
            this.actionButtons = res.actionButtons;

            const displayedColumns = res.columns.filter(c => c.visible);
            const firstColumn = res.allowUpdate ? displayedColumns.filter(c => c.allowEdit && c.type === 'text')[0] : null;

            let mainAdded = false;
            displayedColumns.forEach((col) => {
              if (col.possibleValues) {
                col.possibleValues = col.possibleValues.sort((a,b) => a.label.localeCompare(b.label))
              }

              if (col.formTab) {
                if (!this.fieldTabGroups.find(t => t.tabName === col.formTab)) {
                  const newFormTab: IFormTab = { tabName: col.formTab, sections: [] };
                  this.fieldTabGroups.push(newFormTab);
                  if (newFormTab.tabName === 'Main')
                    mainAdded = true;
                }
              } else if (!mainAdded) {
                this.fieldTabGroups.push({ tabName: 'Main', sections: [] });
                mainAdded = true;
              }
            });
            
            this.fieldTabGroups.forEach(g => {
              if (g.tabName === 'Main') {
                this.devideFormColumnsIntoSections(g, displayedColumns.filter(c => c.formTab === 'Main' || !c.formTab));
              } else {
                this.devideFormColumnsIntoSections(g, displayedColumns.filter(c => c.formTab === g.tabName));
              }
            });
            
            if (this._formData!.rowId === 0)
              this.editing = true;
              
            this.loadSecondaries();
            
            this.selectedFieldTabGroup = this.fieldTabGroups[0];
            this.selectedTabGroup = this.secondaryTabGroups[0];

            if (firstColumn) {
              setTimeout(() => {
                const area = document.getElementById(firstColumn.id);
                area?.focus();
              }, 100);
            }
          }
        },
        error: (err) => { 
          console.log(err)
        }
      });
    }
  }

  setupNewRecord() {
    if (this._formData) {
      this.tableService.getNewTemplate(this._formData?.table).subscribe({
        next: (res) => {
          if (res.rows?.length === 1) {
            this.tableData = res;

            if (this._formData!.copyRow) {
              this.dataRow = this._formData!.copyRow;
              this.tableData.rows[0] = this._formData!.copyRow;
            } else {
              this.dataRow = res.rows[0];
            }

            const displayedColumns = res.columns.filter(c => c.visible);
            const firstColumn = res.allowUpdate ? displayedColumns.filter(
              c => c.allowEdit && (c.type === 'text' || c.type === 'number'))[0] : null;

            let mainAdded = false;
            displayedColumns.forEach((col) => {
              if (col.possibleValues) {
                col.possibleValues = col.possibleValues.sort((a,b) => a.label.localeCompare(b.label))
              }

              if (col.formTab) {
                if (!this.fieldTabGroups.find(t => t.tabName === col.formTab)) {
                  const newFormTab: IFormTab = { tabName: col.formTab, sections: [] };
                  this.fieldTabGroups.push(newFormTab);
                }
              } else if (!mainAdded) {
                this.fieldTabGroups.push({ tabName: 'Main', sections: [] });
                mainAdded = true;
              }
            });
            
            this.fieldTabGroups.forEach(g => {
              if (g.tabName === 'Main') {
                this.devideFormColumnsIntoSections(g, displayedColumns.filter(c => c.formTab === 'Main' || !c.formTab));
              } else {
                this.devideFormColumnsIntoSections(g, displayedColumns.filter(c => c.formTab === g.tabName));
              }
            });
                        
            this.editing = true;

            if (this._formData?.parent)
              this.dataRow[this._formData.parent] = this._formData.parentId;
              
            this.loadSecondaries();
            
            this.selectedFieldTabGroup = this.fieldTabGroups[0];
            this.selectedTabGroup = this.secondaryTabGroups[0];

            if (firstColumn) {
              setTimeout(() => {
                const area = document.getElementById(firstColumn.id);
                area?.focus();
              }, 100);
            }
          }
        },
        error: (err) => console.log(err)
      })
    }
  }

  private initForm() {
    if (this._formData) {
      if (this._formData.rowId) {
        this.loadData();
      } else {
        this.setupNewRecord();
      }
    }  
  }

  private devideFormColumnsIntoSections(tab: IFormTab, displayedColumns: any[]) {
    var q = displayedColumns.length
    if (displayedColumns.length > 15) {
      q = Math.ceil(displayedColumns.length / 4);
      tab.sections[0] = displayedColumns.slice(0, q);
      tab.sections[1] = displayedColumns.slice(q, q * 2);
      tab.sections[2] = displayedColumns.slice(q * 2, q * 3);
      tab.sections[3] = displayedColumns.slice(q * 3, q * 4);
    } else if (displayedColumns.length > 10) {
      q = Math.ceil(displayedColumns.length / 3);
      tab.sections[0] = displayedColumns.slice(0, q);
      tab.sections[1] = displayedColumns.slice(q, q * 2);
      tab.sections[2] = displayedColumns.slice(q * 2, q * 3);
    } else if (displayedColumns.length > 5) {
      q = Math.ceil(displayedColumns.length / 2);
      tab.sections[0] = displayedColumns.slice(0, q);
      tab.sections[1] = displayedColumns.slice(q);
    } else {
      tab.sections[0] = displayedColumns;
    }

    if (q > this.primaryFieldMaxRows)
      this.primaryFieldMaxRows = q;
  }

  public get fieldAreaHeight(): any {
    return { 'height': `${this.primaryFieldMaxRows * 45 + 5}px` };
  }

  loadSecondaries() {
    if (this.tableData?.secondaryControllers && this.tableData.secondaryControllers.length > 0) {
      if (this.tableData!.formSecondaryTabs) {
        this.secondaryTabGroups = this.tableData!.formSecondaryTabs;
      } else {
        this.secondaryTabGroups = ["Secondaries"];
      }

      this.tableData.secondaryControllers.forEach(secondary => {
        var filteredRows = this.getFilteredSecondaryRows(secondary);
        secondary.dataSource = new MatTableDataSource(filteredRows);
      });
    }
  }

  getFilteredSecondaryRows(sec: ISecondaryData) : [] {
    var filteredRows = [];

    if (sec.filterColumn && sec.filterValue && this.dataRow![sec.reference]) {
      filteredRows = this.dataRow![sec.reference]
        .filter((r: any) => r[sec.filterColumn!] === sec.filterValue);
    } else if (this.dataRow![sec.reference]) {
      filteredRows = this.dataRow![sec.reference];
    }

    return filteredRows;
  }

  matCardClass(): string {
    if (this.tableData?.windowSize) {
      if (this.subFormData)
        return "mat-card-full-inactive";
      return "mat-card-full-active";
    } else {
      if (this.subFormData)
        return "mat-card-window-inactive";
      return "mat-card-window-active";
    }
  }

  secondaries(tabGroup: string): any[] {
    if (this.secondaryTabGroups.length == 1)
      return this.tableData?.secondaryControllers ?? [];
    
    return this.tableData?.secondaryControllers?.filter(s => s.tabName === tabGroup) ?? [];
  }

  getChoiceLabel(value: any, column: ITableColumn): string {
    return column.possibleValues.find((v) => v.value === value)?.label ?? "";
  }

  getLangStringText(str?: string): string {
    if (!str)
      return '';
    
    if (str && this.tableData && this.tableData.languageStrings) {
      return this.tableData.languageStrings[str];
    }

    return "Unknown" + (str ? `: ${str}` : '');
  }

  getResLinkTitle(id?: number): string {
    if (!id) return '';

    if (id && this.tableData && this.tableData.resourceLinkInfo) {
      return this.tableData.resourceLinkInfo[id].title;
    }

    return "Unknown" + (id ? `: ${id}` : '');
  }

  hasResLinkPreview(id?: number): boolean {
    if (id && 
      this.tableData && 
      this.tableData.resourceLinkInfo &&
      this.tableData.resourceLinkInfo[id].previewUrl) {
        return true;
    }

    return false;  
  }

  getResLinkUrl(id?: number, secondary?: ISecondaryData): string | undefined {
    if (!id) return '';

    if (secondary) {
      if (!secondary.resourceLinkInfo)
        return undefined;
      return secondary.resourceLinkInfo[id].previewUrl;
    } else if (this.tableData && this.tableData.resourceLinkInfo) {
      return this.tableData.resourceLinkInfo[id].previewUrl;
    }

    return "Unknown" + (id ? `: ${id}` : '');
  }

  openSecondaryForm(secondary: ISecondaryData, event: { formType: FormToOpen, column: ITableColumn, element: any, files?: any }) {
    switch (event.formType) {
      case FormToOpen.LangString: this.openLangString(event.column, event.element, secondary); break;
      case FormToOpen.LangStringSearch: this.openLangStringSearch(event.column, event.element, secondary); break;
      case FormToOpen.ResLink: this.openResLinkEdit(event.column, event.element, secondary); break;
      case FormToOpen.ResLinkSearch: this.openResLinkSearch(event.column, event.element, secondary); break;      
      case FormToOpen.CreateResLink: this.createResLink(event.column, event.element, secondary, event.files)
    }
  }

  openSecondaryRow(secondary: ISecondaryData, id: number) {
    if (secondary.useForm) {
      this.subFormData = {
        name: secondary.entity,
        rowId: id,
        table: secondary.entity,        
      };
    }
  }

  openFkForm(column: ITableColumn, id: number) {
    if (!column.fkEntity)
      return;
    if (id) {
      this.subFormData = {
        name: column.label,
        rowId: id,
        table: column.fkEntity,
        fkColumn: column
      };
    } else {
      this.subFormData = {
        name: column.label,
        rowId: 0,
        table: column.fkEntity,
        fkColumn: column
      };
    }
  }

  newSecondaryRecord(secondary: ISecondaryData, row?: any) {
    if (!secondary)
      return;

    if (secondary.useForm) {
      this.subFormData = {
        name: secondary.entity,
        rowId: 0,
        table: secondary.entity,
        secondary: secondary,
        parent: this.tableData?.primaryKey,
        parentId: this._formData?.rowId,
        newRecord: true,
        copyRow: row
      }
    } else {
      const newRecord = 
        row == undefined 
        ? JSON.parse(JSON.stringify(secondary.defaultNewRecord))
        : JSON.parse(JSON.stringify(row));
    
      if (row) {
        newRecord[secondary.primaryKey] = 0;

        // secondary.data!.secondaryControllers?.forEach((sec: any) => {
        //   if (newRecord[sec.table]) {
        //     newRecord[sec.table].forEach((row: any) => {
        //       row[sec.data!.primaryKey] = 0;
        //     });
        //   }
        // });
      }
      newRecord.newRecord = true;
      newRecord[this.tableData!.primaryKey] = this._formData!.rowId;

      this.editing = true;
      if (this.dataRow![secondary.reference!]) {
        this.dataRow![secondary.reference].push(newRecord);
      }
      else {
        this.dataRow![secondary.reference] = [newRecord];
      }
      
      var filteredRows = this.getFilteredSecondaryRows(secondary);
      secondary.dataSource = new MatTableDataSource(filteredRows);
    }
  }

  subFormClosing(dataRow: any) {
    if (this.subFormData?.secondary && dataRow) {
      if (dataRow === -1) {
        // Deleted
        let ind = this.tableData!.rows[0][this.subFormData.secondary.reference] // this.subFormData.secondary.data!.rows
          .findIndex((r: any) => r[this.subFormData!.secondary!.primaryKey] === this.subFormData!.rowId);

          this.tableData!.rows[0][this.subFormData.secondary.reference].splice(ind, 1);        
        this.subFormData!.secondary!.dataSource = new MatTableDataSource(this.tableData!.rows[0][this.subFormData.secondary.reference]);
      } else if (!this.subFormData.newRecord) {
        // Update
        if (this.subFormData.secondaryRow) {
          this.subFormData.secondary.columns.forEach((col) => this.subFormData!.secondaryRow[col.id] = dataRow[col.id]);
          this.subFormData.secondary.dataSource = new MatTableDataSource(this.tableData!.rows[0][this.subFormData.secondary.reference]);
        }        
      } else {
        // New record
        this.tableData!.rows[0][this.subFormData.secondary.reference].push(dataRow);
        this.subFormData!.secondary!.dataSource = new MatTableDataSource(this.tableData!.rows[0][this.subFormData.secondary.reference]);
      }
    } else if (this.subFormData?.fkColumn && dataRow) {
      if (dataRow === -1) {
        // Deleted
        this.dataRow[this.subFormData.fkColumn.id] = undefined;
      } else if (!this.subFormData.rowId) {
        // New record
        this.dataRow[this.subFormData.fkColumn.id] = dataRow;
        if (this.subFormData.fkColumn.possibleValues) {
          this.subFormData.fkColumn.possibleValues.push({
            label: 'New',
            value: dataRow
          });          
        }
        
        this.editing = true;
      }

      // Reload?
    }

    this.subFormData = undefined;
  }

  deleteSecondaryRow(secondary: ISecondaryData, row: any) {
    const ind = this.dataRow![secondary.reference].indexOf(row);
    this.dataRow![secondary.reference].splice(ind!, 1);
    this.editing = true;
  }

  formDataChanged(element: any, column: ITableColumn) {
    if (!element.changed)
      element.changed = [column.id];
    else
      element.changed.push(column.id);
    
    this.editing = true;
  }

  save(andClose = false) {
    let canSave = true;
    this.columns?.forEach(c => {
      if (c.required) {
        if (c.type === 'checkbox' && this.dataRow![c.id] == undefined) {
          this.dataRow![c.id] = false;
        } else if (this.dataRow![c.id] !== 0 && !this.dataRow![c.id]) {
          if (!this.dataRow!.missing)
            this.dataRow!.missing = [c.id];
          else
            this.dataRow!.missing.push(c.id);
            
          canSave = false;
        }
      }
    });

    this.tableData!.secondaryControllers?.forEach(sec => {
      if (sec.allowUpdate) {
        sec.columns.forEach(c => {
          if (c.required) {
            this.dataRow![sec.reference]?.forEach((row: any) => {
              if (c.type === 'checkbox' && row[c.id] == undefined) {
                row[c.id] = false;
              } else if (!row[c.id] && !(!c.id.endsWith('Id') && row[c.id] === 0)) {
                if (!row.missing)
                  row.missing = [c.id];
                else
                  row.missing.push(c.id);
  
                canSave = false;
              }
            });
          }
        });
      }
    })

    if (!canSave) {
      console.log("Can't save, missing required data")
      return;
    }

    this.fixDateTimeFormats();

    if (this._formData!.rowId === 0) {
      this.tableService.post(this._formData!.table, this.dataRow!)
        .subscribe({
          next: (res: any) => {
            this.clearColors();
            this._formData!.rowId = res;
            this.dataRow[this.tableData!.primaryKey] = res;
            this.changedFormData = JSON.parse(JSON.stringify(this.dataRow!));            
            if (andClose) {
              this.doClose();
            } else {
              this.loadData();
            }
          },
          error: () => this.editing = true
      });
    } else {
      this.tableService.put(this._formData!.table, this.dataRow!)
      .subscribe({
        next: (res) => {     
          this.clearColors();    
          this.changedFormData = res;
          if (andClose) {
            this.doClose();
          } else {
            this.dataRow! = res;
          }
        },
        error: () => this.editing = true
      });
    }
    
    this.editing = false;
  }

  fixDateTimeFormats()
  {
    this.tableData?.columns.forEach((c) => {
      if (c.type === 'date' && this.tableData!.rows[0][c.id] != undefined) {
        this.tableData!.rows[0][c.id] = new Date(this.tableData!.rows[0][c.id]).toISOString().split('T')[0];
      }
      else if (c.type === 'time' && this.tableData!.rows[0][c.id] != undefined) {
        this.tableData!.rows[0][c.id] = new Date(this.tableData!.rows[0][c.id]).toISOString().split('T')[1];
      }
    });
  }

  clearColors() {
    if (this.tableData && this.dataRow?.missing) {
      this.dataRow.missing = [];
    }
    if (this.tableData && this.dataRow.changed) {
      this.dataRow.changed = [];
    }

    this.tableData!.secondaryControllers?.forEach(sec => {
      if (!sec.useForm) {
        this.dataRow![sec.reference]?.forEach((row: any) => {
          if (row.missing) {
            row.missing = [];
          }
          if (row.changed) {
            row.changed = [];
          }
        });
      }
    });
  }

  close() {
    if (this.editing) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
          title: 'Unsaved data',
          message: 'Are you sure you want to discard your changes?',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.doClose();
        }
      });
    } else {
      this.doClose();  
    }
  }

  private doClose() {
    if (this.changedFormData) {
        this.closing.emit(this.changedFormData);
    } else {
      this.closing.emit();
    }
  }

  deleteRecord() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
          title: 'Delete',
          message: 'Are you sure you want to delete this record?',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.tableService.delete(this._formData!.table, [this._formData!.rowId]).subscribe({
            next: () => this.closing.emit(-1)
          });
        }
      });
  }

  formInputClass(column: ITableColumn) : string {
    if (!column.allowEdit) {
      if (column.type === 'resLink')
        return 'field-input-no-edit res-link' 
      return 'field-input-no-edit';
    }

    let inputClass = 'field-input';

    if (column.type === 'textarea')
      inputClass += '-textarea';
    else if (column.type.startsWith('date')) {
      inputClass += '-date';
    }

    if (this.dataRow.missing && this.dataRow.missing.includes(column.id)) {
      inputClass += '-missing'
    } else if (this.dataRow.changed && this.dataRow.changed.includes(column.id)) {
      inputClass += '-edited';
    }

    if (column.type === 'resLink')
      inputClass += '  res-link'
    return inputClass;
  }
  
  isMissingValue(element: any, col: string): boolean {
    if (element.missing && element.missing.find((e: any) => e === col)) {
      return true;
    }
    return false;
  }

  setEditMemo(data: any, column: ITableColumn, secondary: any) {
    if (column.type === 'textarea') {
      this.editMemo = { data: data, column: column, secondary: secondary };

      setTimeout(() => {
        const area = document.getElementById('memo-area');
        area?.focus();
      }, 100);
    }
  }

  onMemoChange(memo: IEditMemo) {
    if (memo) {
      this.editing = true;
      if (memo.data) {
        if (!memo.data.changed)
          memo.data.changed = [memo.column.id];
        else
          memo.data.changed.push(memo.column.id);
      }
    }
  }

  openLangString(column: ITableColumn, row: any, secondary?: any) {
    if (row[column.id]) {
      this.editLangString = {
        stringCode: row[column.id],
        row: row,
        columnId: column.id,
        secondary: secondary
      };
    }
  }

  openLangStringSearch(column: ITableColumn, row: any, secondary?: any) {
    this.searchLangString = {
      column: column,
      row: row,
      secondary: secondary
    };
  }

  onEditLangStringClosing(result?: ILangString) {
    if (result) {
      if (this.editLangString!.secondary) {
        if (!this.editLangString!.secondary.languageStrings) {
          this.tableData!.languageStrings = {};
        } 
        this.editLangString!.secondary.languageStrings[result.code] = result.text;
      } else {
        if (!this.tableData!.languageStrings) {
          this.tableData!.languageStrings = {};
        } 
        this.tableData!.languageStrings[result.code] = result.text;
      }
    }

    this.editLangString = undefined;
  }

  onLangStringSearchClosing(str?: ILangString) {
    if (this.searchLangString && this.tableData) {
      if (str &&
        this.searchLangString.row[this.searchLangString.column.id] !== str.code) {
          if (this.searchLangString.secondary) {
            if (!this.searchLangString.secondary.languageStrings) { 
              this.searchLangString.secondary.languageStrings = { };
              this.searchLangString.secondary.languageStrings[str.code] = str.text;
            } else if (!this.searchLangString.secondary.languageStrings[str.code]) {
              this.searchLangString.secondary.languageStrings[str.code] = str.text;
            }
          } else {
            if (!this.tableData.languageStrings) {
              this.tableData.languageStrings = {};
            } 
            this.tableData.languageStrings[str.code] = str.text;
          }

          this.searchLangString.row[this.searchLangString.column.id] = str.code;
          this.formDataChanged(this.searchLangString.row, this.searchLangString.column);
      }
      this.searchLangString = undefined;
    }
  }

  openResLinkSearch(column: ITableColumn, row: any, secondary?: any) {
    if (column.allowEdit) {
      this.searchResLink = {
        column: column,
        row: row,
        secondary: secondary
      };
    }
  }

  openResLinkEdit(column: ITableColumn, row: any, secondary?: any) {
    this.editResLink = {
      resInfo: { resourceId: row[column.id], newRecord: false },
      column: column,
      row: row,
      secondary: secondary
    };
  }

  createResLink(column: ITableColumn, row: any, secondary?: any, files?: any) {
    this.editResLink = {
      resInfo: { resourceId: 0, newRecord: true, file: files ? files[0] : undefined },
      column: column,
      row: row,
      secondary: secondary
    };
  }

  onEditResLinkClosing(res?: ResourceLink) {
    if (res && this.editResLink?.resInfo) {
      if (this.editResLink.secondary) {
        if (!this.editResLink.secondary.resourceLinkInfo) { 
          this.editResLink.secondary.resourceLinkInfo = { };
        } 
        this.editResLink.secondary.resourceLinkInfo[res.resourceLinkId] = {
          id: res.resourceLinkId,
          title: res.title,
          originalUrl: res.originalUrl,
          previewUrl: res.previewUrl,
        };        
      } else if (this.tableData) {
        if (!this.tableData.resourceLinkInfo) {
          this.tableData.resourceLinkInfo = {};
        }
        this.tableData.resourceLinkInfo[res.resourceLinkId] = {
          id: res.resourceLinkId,
          title: res.title,
          originalUrl: res.originalUrl,
          previewUrl: res.previewUrl,
        };
      }
      
      this.editResLink.row[this.editResLink.column.id] = (!res.resourceLinkId || res.resourceLinkId < 1 ? null : res.resourceLinkId);
      this.formDataChanged(this.editResLink.row, this.editResLink.column);
    }

    this.editResLink = undefined;
  }

  onResLinkSearchClosing(res?: IResourceSearchResult) {
    if (this.searchResLink && this.tableData) {
      if (res && this.searchResLink.row[this.searchResLink.column.id] !== res.id) {
        if (this.searchResLink.secondary) {
          if (!this.searchResLink.secondary.resourceLinkInfo) { 
            this.searchResLink.secondary.resourceLinkInfo = { };
            this.searchResLink.secondary.resourceLinkInfo[res.id] = {
              id: res.id,
              originalUrl: res.originalUrl,
              previewUrl: res.previewUrl,
              title: res.title
            };
          } else if (!this.searchResLink.secondary.resourceLinkInfo[res.id]) {
            this.searchResLink.secondary.resourceLinkInfo[res.id] = {
              id: res.id,
              originalUrl: res.originalUrl,
              previewUrl: res.previewUrl,
              title: res.title
            };
          }
        } else {
          if (!this.tableData.resourceLinkInfo) {
            this.tableData.resourceLinkInfo = {};
          } 
          
          this.tableData.resourceLinkInfo[res.id] = {
            id: res.id,
            originalUrl: res.originalUrl,
            previewUrl: res.previewUrl,
            title: res.title
          };
        }

        this.searchResLink.row[this.searchResLink.column.id] = (!res.id || res.id < 1 ? null : res.id);
        this.formDataChanged(this.searchResLink.row, this.searchResLink.column);
      }
    }

    this.searchResLink = undefined;
  }

  triggerActionButton(url: string) {
    this.actionButtonsDisabled = true;
    this.tableService.put(url, null).subscribe({
      next: () => this.actionButtonsDisabled = false,
      error: (err) => {
        this.actionButtonsDisabled = false;
        console.log(err)
    }});
  }
}

interface IFormEditMemo extends IEditMemo {
  secondary: ISecondaryData;
}

interface IFormTab {
  tabName: string;
  sections: any[];
}