<div class="form-base">
    <mat-card class="card">
        <mat-card-title>
            {{title}}
        </mat-card-title>
        <mat-card-content class="card-content">
            <div class="search-input-area">
                <mat-form-field appearance="standard" class="search-field">
                    <mat-label>Search</mat-label>
                    <input 
                        matInput 
                        id="search-input"
                        type="text" 
                        [(ngModel)]="searchString" 
                        (keypress)="searchKeyPressed($event)">
                    <button *ngIf="searchString" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-checkbox
                    [(ngModel)]="state.sameCodePrefix">
                        Same code prefix
                </mat-checkbox>
                <button mat-raised-button color="basic" [disabled]="!searchString" (click)="search()">Search</button>
            </div>            
            <div class="search-result-area">
                <div *ngIf="searching && !searchResult">Nothing found...</div>
                <div *ngIf="searching && searchResult">
                    <div *ngFor="let str of searchResult" (click)="select(str)" class="search-list-item">
                        {{str.text}}
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions class="card-actions">
            <button mat-raised-button (click)="empty()">Assign Empty</button>
            <button mat-raised-button (click)="cancel()">Cancel</button>
            <button mat-raised-button (click)="state.searching = !state.searching">
                {{state.searching ? 'Create new' : 'Search'}}
            </button>
        </mat-card-actions>
    </mat-card>
</div>

<lang-form
    *ngIf="!state.searching"
    [stringCode]="code"
    [newRecord]="true"
    (closing)="newFormClosing($event)">
</lang-form>