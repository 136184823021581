import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === environment.jwtValidationUrl || req.url === environment.jwtRefreshUrl) {
      return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return this.handleError(err);          
        })
      )
    } else if (!this.isRefreshing) {
      if (this.authService.isAuthenticated) {
        let authReq = req;

        const token = this.authService.getToken();
        if (token) {
          authReq = this.addTokenToHeader(req, token);
        }

        return next.handle(authReq)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              if (err instanceof HttpErrorResponse && err.status === 401) {
                return this.handle401Error(req, next);
              } else {
                return this.handleError(err);
              }
            })
          )
      } else {
        return this.refreshAccessToken(req, next);
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((token: string) => next.handle(this.addTokenToHeader(req, token)))
      );
    }
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("handle401Error")

    if (!this.isRefreshing) {      
      return this.refreshAccessToken(req, next);
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap((token: string) => {
        return next.handle(this.addTokenToHeader(req, token));
      })
    );
  }

  private refreshAccessToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isRefreshing = true;
    return this.authService.refreshJwt().pipe(
      switchMap((res: any) => {
        this.authService.saveAccessToken(res);
        this.isRefreshing = false;
        this.refreshTokenSubject.next(res.token);
        return next.handle(this.addTokenToHeader(req, res.token));
      }),
      catchError((err) => {
        this.isRefreshing = false;
        throw new Error(err);
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    window.alert(errorMessage);
    throw new Error(errorMessage);
  }

  private addTokenToHeader(req: HttpRequest<any>, token: string) : HttpRequest<any> {
    return req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
  }
}
