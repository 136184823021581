import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '@Services/app.service';

@Component({
  selector: 'app-text-widget',
  templateUrl: './text-widget.component.html',
  styleUrls: ['./text-widget.component.scss']
})
export class TextWidgetComponent implements OnInit {
  @Input() label!: string;
  @Input() text!: string;

  @Output() showWidgetClicked: EventEmitter<any> = new EventEmitter<any>();

  public isAdmin = false;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.appService.getMe().subscribe({
      next: (res) => {
        this.isAdmin = res?.isAdministrator;
      }
    })
  }

  public openWidgetSettings() {
    this.showWidgetClicked.emit();
  }
}
