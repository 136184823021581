import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { IResourceFormInfo } from '@Models/resource-form-info';
import { ResourceLink } from '@Models/resource-link';
import { IResourceSearchResult } from '@Models/resource-search-result';
import { ResourceService } from '@Services/resource.service';

@Component({
  selector: 'resource-search',
  templateUrl: './resource-search.component.html',
  styleUrls: ['./resource-search.component.scss']
})
export class ResourceSearchComponent implements AfterViewInit {
  public searchResult: IResourceSearchResult[] | undefined;
  public searchString: string = "";
  public searching = false;
  public state = { searching: true, newRecord: false, searchImages: false, imagesOnly: false }
  public newText = "";
  public resourceInfo: IResourceFormInfo | undefined;
  public viewType = 'tiles';

  @Input() title: string = "";
  @Output() closing: EventEmitter<IResourceSearchResult | undefined> = new EventEmitter<IResourceSearchResult | undefined>

  constructor(private resourceService: ResourceService) { }

  ngAfterViewInit(): void {
    this.getLastest();
  }

  searchKeyPressed($event: any) {
    if ($event.key === "Enter") {
      this.search();
    }
  }

  getLastest() {
    this.resourceService.getLatest().subscribe({
      next: (res: IResourceSearchResult[]) => {
        this.searching = true;
        this.searchResult = res;
        this.state.imagesOnly = this.state.searchImages;
      },
      error: (err) => console.log(err)
    });
  }

  search() {
    if (!this.searchString) 
      return;

    this.resourceService.search(this.searchString, this.state.searchImages).subscribe({
      next: (res: IResourceSearchResult[]) => {
        this.searching = true;
        this.searchResult = res?.sort((a,b) => a.title.localeCompare(b.title));
        this.state.imagesOnly = this.state.searchImages;
      },
      error: (err) => console.log(err)
    });
  }

  clearSearch() {
    this.searchString = "";
    this.searchResult = undefined;
  }

  select(resSr: IResourceSearchResult) {
    this.closing.emit(resSr);
  }

  newFormClosing(resSr: IResourceSearchResult | undefined) {
    if (resSr) {
      this.closing.emit(resSr);
    } else {
      this.state.searching = true;
    }
  }

  empty() {
    this.closing.emit({ id: -1, title: '', ending: '', originalUrl: ''  });
  }

  cancel() {
    this.closing.emit(undefined);
  }

  createNew() {
    this.resourceInfo = {
      resourceId: 0,
      newRecord: true
    };
  }

  onNewResourceLinkFormClosing(res: ResourceLink) {
    if (res) {
      const resSr: IResourceSearchResult = {
        id: res.resourceLinkId,
        title: res.title,
        ending: res.fileEnding,
        originalUrl: res.originalUrl,
        previewUrl: res.previewUrl,
      };

      this.select(resSr);
    }

    this.resourceInfo = undefined;
  }

  get viewTiles() : boolean {
    return this.viewType === 'tiles';
  }
}
