<div *ngIf="formData" class="table-form-base">
    <mat-card [class]="matCardClass()" cdkDrag [cdkDragDisabled]="tableData?.windowSize">
        <mat-card-title class="title-area">
            <div>
                {{formData.name}} {{formData.rowId === 0 ? '(New Record)' : ''}}
            </div>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-card-title>
        <mat-card-content class="form-card-content">
            <div class="primary-tab-area">
                <div class="primary-tab-group">
                    <div *ngIf="fieldTabGroups.length > 1" class="tab-group-header">
                        <button [class]="tab === selectedFieldTabGroup ? 'tab-button-active' : 'tab-button'" 
                            *ngFor="let tab of fieldTabGroups" 
                            (click)="selectedFieldTabGroup = tab">
                                {{tab.tabName}}
                        </button>
                    </div>
                    <div class="field-area" [ngStyle]="fieldAreaHeight">
                        <div *ngFor="let section of selectedFieldTabGroup?.sections">
                            <div class="field-section">
                                <div *ngFor="let column of section">
                                    <div>
                                        <mat-label>{{ column.label }}{{ column.required ? '*' : '' }}</mat-label>
                                    </div>
                                    <div>
                                        <ng-container [ngSwitch]="column.type">                        
                                            <ng-container *ngSwitchCase="'choice'">
                                                <div class="choice-area">
                                                    <mat-select
                                                        [class]="formInputClass(column)" 
                                                        [disabled]="!column.allowEdit" 
                                                        [(value)]="dataRow[column.id]" 
                                                        (valueChange)="formDataChanged(dataRow, column)"
                                                        (mousedown)="$event.stopPropagation()">
                                                            <mat-option *ngFor="let opt of column.possibleValues" [value]="opt.value">{{opt.label}}</mat-option>
                                                    </mat-select>
                                                    <button *ngIf="column.fkEntity" mat-icon-button class="fk-button" [disabled]="subFormData || editing" (click)="openFkForm(column, dataRow[column.id])">
                                                        <mat-icon>edit_note</mat-icon>
                                                    </button>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'textarea'">
                                                <div [class]="formInputClass(column)" (click)="setEditMemo(dataRow, column, null)" (mousedown)="$event.stopPropagation()">
                                                    {{ dataRow[column.id] }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'checkbox'">
                                                <mat-checkbox [disabled]="!column.allowEdit" [(ngModel)]="dataRow[column.id]" (change)="formDataChanged(dataRow, column)" (mousedown)="$event.stopPropagation()"></mat-checkbox>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'date'">
                                                <div class="field-date-area">
                                                    <input matInput
                                                        [matDatepicker]="picker" 
                                                        [class]="formInputClass(column)"
                                                        [disabled]="!column.allowEdit"
                                                        [(ngModel)]="dataRow[column.id]"
                                                        (dateChange)="formDataChanged(dataRow, column)"
                                                        (mousedown)="$event.stopPropagation()">
                                                    <mat-datepicker-toggle matIconSuffix [for]="picker" style="margin-top: -7px"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'datetime_local'">
                                                <!-- Date and Time Picker !?-->
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'langStr'">
                                                <div class="flex-row">
                                                    <input 
                                                        matInput 
                                                        [class]="formInputClass(column)" 
                                                        type="text" 
                                                        disabled="true" 
                                                        [value]="getLangStringText(dataRow[column.id])">
                                                    <mat-icon 
                                                        *ngIf="dataRow[column.id]"
                                                        class="field-icon"
                                                        (click)="openLangString(column, dataRow)">
                                                            edit
                                                    </mat-icon>
                                                    <mat-icon 
                                                        class="field-icon"
                                                        (click)="openLangStringSearch(column, dataRow)">
                                                            translate
                                                    </mat-icon>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'resLink'">
                                                <div class="flex-row" appDragDropFileUpload (fileDropped)="createResLink(column, dataRow, null, $event)">
                                                    <input 
                                                        matInput
                                                        [class]="formInputClass(column)" 
                                                        type="text" disabled="true" 
                                                        [value]="getResLinkTitle(dataRow[column.id])"
                                                        (mousedown)="$event.stopPropagation()">
                                                    <div *ngIf="dataRow[column.id] && hasResLinkPreview(dataRow[column.id])" class="resource-preview">
                                                        <img class="preview-image" [src]="getResLinkUrl(dataRow[column.id])" />
                                                    </div>
                                                    <mat-icon *ngIf="dataRow[column.id]" (click)="openResLinkEdit(column, dataRow)" class="res-icon">edit</mat-icon>
                                                    <mat-icon *ngIf="!dataRow[column.id]" (click)="createResLink(column, dataRow)" class="res-icon">upload</mat-icon>
                                                    <mat-icon (click)="openResLinkSearch(column, dataRow)" class="res-icon">plagiarism</mat-icon>                                                    
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <input
                                                    [id]="column.id" 
                                                    matInput 
                                                    [class]="formInputClass(column)" 
                                                    [disabled]="!column.allowEdit" 
                                                    [type]="column.type" 
                                                    [(ngModel)]="dataRow[column.id]" 
                                                    (keydown)="formDataChanged(dataRow, column)" 
                                                    (mousedown)="$event.stopPropagation()">
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="secondaryTabGroups && secondaryTabGroups.length === 1" class="tab-group-body">
                <div class="secondary-area" *ngFor="let secondary of secondaries(selectedTabGroup)">
                    <app-form-secondary
                        [secondaryData]="secondary"
                        (dataChanged)="formDataChanged($event.dataRow, $event.column)"
                        (openRowInForm)="openSecondaryRow(secondary, $event)"
                        (newRow)="newSecondaryRecord(secondary, $event)"
                        (deleteRow)="deleteSecondaryRow(secondary, $event)"
                        (openForm)="openSecondaryForm(secondary, $event)"></app-form-secondary>
                </div>
            </div>
            <div *ngIf="secondaryTabGroups && secondaryTabGroups.length > 1" class="tab-group">
                <div class="tab-group-header">
                    <button [class]="tab === selectedTabGroup ? 'tab-button-active' : 'tab-button'" *ngFor="let tab of secondaryTabGroups" (click)="selectedTabGroup = tab">
                        {{tab}}
                    </button>
                </div>
                <div class="tab-group-body">
                    <div class="secondary-area" *ngFor="let secondary of secondaries(selectedTabGroup)">
                        <app-form-secondary
                            [secondaryData]="secondary"
                            (dataChanged)="formDataChanged($event.dataRow, $event.column)"
                            (openRowInForm)="openSecondaryRow(secondary, $event)"
                            (newRow)="newSecondaryRecord(secondary, $event)"
                            (deleteRow)="deleteSecondaryRow(secondary, $event)"
                            (openForm)="openSecondaryForm(secondary, $event)"></app-form-secondary>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions class="form-footer">
            <div class="form-footer-left">
                <button *ngFor="let actionBtn of actionButtons" mat-raised-button [disabled]="editing || actionButtonsDisabled" (click)="triggerActionButton(actionBtn.actionUrl)">{{ actionBtn.label }}</button>
            </div>
            <div class="form-footer-middle">
                <button *ngIf="tableData?.allowDelete" mat-raised-button [disabled]="subFormData" (click)="deleteRecord()">Delete Record</button>
            </div>
            <div class="form-footer-right">
                <button mat-raised-button [disabled]="subFormData" (click)="close()">Close</button>
                <button mat-raised-button [disabled]="subFormData || !editing" (click)="save()">Save</button>
                <button mat-raised-button [disabled]="subFormData ||!editing" (click)="save(true)">Save & Close</button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>

<app-table-form 
    *ngIf="subFormData" 
    [formData]="subFormData" 
    (closing)="subFormClosing($event)">
</app-table-form>

<memo-card 
    *ngIf="editMemo"
    [editMemo]="editMemo" 
    (dataChanged)="onMemoChange($event)">
</memo-card>

<lang-form 
    *ngIf="editLangString"
    [stringCode]="editLangString.stringCode"
    [newRecord]="false"
    (closing)="onEditLangStringClosing($event)">
</lang-form>

<lang-search 
    *ngIf="searchLangString"
    [title]="searchLangString.column.label"
    [code]="searchLangString.column.langStringBaseCode!"
    (closing)="onLangStringSearchClosing($event)">    
</lang-search>

<resource-search 
    *ngIf="searchResLink"
    [title]="searchResLink.column.label"
    (closing)="onResLinkSearchClosing($event)">
</resource-search>

<resource-form
    *ngIf="editResLink"
    [resourceInfo]="editResLink.resInfo"
    (onFormClosing)="onEditResLinkClosing($event)">
</resource-form>