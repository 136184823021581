import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private hubConnection!: signalR.HubConnection;
  private subject = new Subject<string>();

  constructor() { }

  public startConnection() {
    if (environment.eventEndpoint) {
      this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Error)
        .withUrl(environment.eventEndpoint)
        .withAutomaticReconnect()
        .build();

        this.hubConnection.on('event', (data) => {
          this.subject.next(data);
        });

        this.hubConnection.start();
    }
  }

  public subscribeToUpdates(): Observable<string> {
    if (!this.hubConnection)
      this.startConnection();

    return this.subject;
  }
}
