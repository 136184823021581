import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IResourceFormInfo } from '@Models/resource-form-info';
import { ResourceLink } from '@Models/resource-link';
import { ResourceService } from '@Services/resource.service';

@Component({
  selector: 'resource-form',
  templateUrl: './resource-form.component.html',
  styleUrls: ['./resource-form.component.scss']
})
export class ResourceFormComponent implements OnChanges {

  @Input() resourceInfo?: IResourceFormInfo;
  @Output() onFormClosing: EventEmitter<ResourceLink> = new EventEmitter<ResourceLink>();

  public resourceLink?: ResourceLink;
  public newFile?: File;
  public editing!: boolean;
  hasChanged = false;
  public saving = false;

  public searchThumbnailLink = false;
  public editThumbnailInfo: IResourceFormInfo | undefined;
  
  public missing: string[] = [];

  constructor(private resourceService: ResourceService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resourceInfo) {
      if (this.resourceInfo.newRecord) {
        this.loadDefaultNewRecord();
      } else {
        this.loadResourceLink();
      }
    }    
  }

  loadResourceLink() {
    this.resourceService.get(this.resourceInfo!.resourceId).subscribe({
      next: (res) => {
        this.resourceLink = res;
        this.resourceLink.changed = {};
        this.editing = false;
        this.missing = [];
      },
      error: (err) => console.log(err)
    });
  }

  loadDefaultNewRecord() {
    this.resourceService.getNewTemplate().subscribe({
      next: (res) => {
        this.resourceLink = res;
        this.resourceLink.changed = {};
        this.newFile = this.resourceInfo?.file;
        this.editing = true;
        this.missing = [];
      },
      error: (err) => console.log(err)
    });
  }

  formDataChanged(column: string) {
    this.resourceLink!.changed[column] = true;
    this.editing = true;
  }

  get thumbnailClass(): string {
    return this.fieldClass('thumbnailId') + ' res-link';
  }

  fieldClass(column: string): string {
    if (this.missing.includes(column))
      return 'input-missing';
    return this.resourceLink!.changed[column] ? 'input-changed' : 'input-standard';
  }

  fileSelected(fileEvent: any) {
    this.newFile = fileEvent.target.files[0];
    this.setTitle();
    this.editing = true;
  }

  onFileDropped(files: any) {
    this.newFile = files[0];
    this.setTitle();
    this.editing = true;
  }

  setTitle() {
    if (this.newFile && this.resourceLink && !this.resourceLink.title) {
      var name = this.newFile.name;
      if (name.includes('.')) {
        const nameArr = name.split('.');
        name = nameArr[0];
      }
      this.resourceLink.title = name;
    }
  }

  save(andClose: boolean = false) {
    if (!this.resourceLink) {
      console.log("No resourceLink object existed!");
      return;
    }

    if (!this.resourceLink.title || !this.resourceLink.resourceLinkTypeId) {
      if (!this.resourceLink.title) {
        this.missing.push('title');
      } 
      if (!this.resourceLink.resourceLinkTypeId) {
        this.missing.push('resourceLinkTypeId');
      }

      return;
    }

    const formData = new FormData();
    if (this.newFile) {
      formData.append("file", this.newFile);
    }

    if (!this.resourceLink.resourceLinkId) { // NEW
      if (!this.newFile) {
        window.alert('Cannot Save, add a file!');
        return;
      }

      formData.append("title", this.resourceLink.title);
      formData.append("resourceLinkTypeId", this.resourceLink.resourceLinkTypeId.toString());

      if (this.resourceLink.description) {
        formData.append("description", this.resourceLink.description);
      }
      if (this.resourceLink.thumbnailId) {
        formData.append("thumbnailId", this.resourceLink.thumbnailId.toString());
      }

      this.saving = true;

      this.resourceService.post(formData).subscribe({
        next: (res) => {
          if (andClose) {
            this.onFormClosing.emit(res);
          }
          else {
            this.resourceLink = res;
            this.resourceLink.changed = {};
            this.editing = false;
            this.hasChanged = true;
            this.missing = [];
          }

          this.saving = false;
        },
        error: (err) => {
          console.log(err);
          this.saving = false;
        },      
      });
    } else { // PATCH
      this.saving = true;
      var empty: any = null;

      if (this.resourceLink.changed['title'])
        formData.append("title", this.resourceLink.title);
      if (this.resourceLink.changed['resourceLinkTypeId'])
        formData.append("resourceLinkTypeId", this.resourceLink.resourceLinkTypeId.toString());
      if (this.resourceLink.changed['description'])
        formData.append("description", this.resourceLink.description);

      if (this.resourceLink.changed['thumbnailId']) {
        if (this.resourceLink.thumbnailId)
          formData.append("thumbnailId", this.resourceLink.thumbnailId.toString());
        else
          formData.append("thumbnailId", empty);
      }
      
      this.resourceService.patch(this.resourceLink.resourceLinkId, formData).subscribe({
        next: (res) => {
          if (andClose)
            this.onFormClosing.emit(res);
          else {
            this.resourceLink = res;
            this.resourceLink.changed = {};
            this.editing = false;
            this.hasChanged = true;
            this.missing = [];
            this.newFile = undefined;
          }

          this.saving = false;
        },
        error: (err) => {
          console.log(err);
          this.saving = false;
        },      
      });
    }
  }

  close() {
    if (this.hasChanged)
      this.onFormClosing.emit(this.resourceLink);
    else
      this.onFormClosing.emit();
  }

  getThumbnailTitle(): string {
    return this.resourceLink?.thumbnailTitle ?? '';
  }

  createResLinkForThumbnail(files?: any) {
    this.editThumbnailInfo = {
      resourceId: 0, 
      newRecord: true, 
      file: files ? files[0] : undefined
    };
  }

  editThumbnailResLink() {
    if (this.resourceLink?.thumbnailId) {
      this.editThumbnailInfo = {
        resourceId: this.resourceLink.thumbnailId,
        newRecord: false
      }
    }    
  }

  onEditThumbnailClosing(resLink: any) {
    if (resLink) {
      if (this.editThumbnailInfo?.newRecord) {
        this.resourceLink!.thumbnailId = resLink.resourceLinkId;
      }

      this.resourceLink!.thumbnailTitle = resLink.title;
      this.resourceLink!.thumbnailUrl = resLink.url ?? resLink.fileUrl;
    }

    this.editThumbnailInfo = undefined;
  }

  onResLinkSearchClosing(event: any) {
    if (event && this.resourceLink) {
      this.resourceLink.thumbnailId = event.id;
      this.resourceLink.thumbnailTitle = event.title;
      this.resourceLink.thumbnailUrl = event.url ?? event.fileUrl;
    }
    this.searchThumbnailLink = false;
  }

  openResLinkSearch() {
    this.searchThumbnailLink = true;
  }

  public get urlDisabled(): boolean {    
    return this.resourceLink != undefined && (
      (this.resourceLink.originalUrl && this.resourceLink.previewUrl == undefined) 
      || 
      (this.resourceLink.previewUrl == undefined && this.newFile != undefined)
    );
  }
}
