<ng-container *ngFor="let param of parameters">
  <ng-container [ngSwitch]="param.type">
    <ng-container *ngSwitchCase="'choice'">
      <mat-form-field class="table-menu" appearance="fill" style="margin-left: 5px">
        <mat-label>Choose {{param.label}}</mat-label>
        <mat-select [(value)]="param.value" (valueChange)="valueChanged()">
          <mat-option *ngFor="let p of param.possibleValues" [value]="p.value">{{ p.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'checkbox'">
      <mat-checkbox class="checkbox-input" [(ngModel)]="param.value" (change)="valueChanged">{{ param.label }}</mat-checkbox>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-form-field appearance="fill" style="margin-left: 5px">
        <mat-label>{{param.label}}</mat-label>
        <input matInput [(ngModel)]="param.value" [type]="param.type" (keydown.enter)="tryRun()" (ngModelChange)="valueChanged()">
      </mat-form-field>
    </ng-container>
  </ng-container>
</ng-container>
<button
  mat-button
  color="primary"
  [disabled]="!allowRun"
  (click)="run()">
    Run
</button>
