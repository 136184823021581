<div class="widget-area">
  <div class="header-area">
    <h1 style="margin-left: 10px">
      {{widgetSettings?.label}}
    </h1>
    <div class="button-area">
      <button *ngIf="isAdmin" mat-icon-button class="copy-to-clipboard" (click)="openWidgetSettings()">
        <mat-icon>design_services</mat-icon>
      </button>
      <button mat-icon-button class="copy-to-clipboard" (click)="copyToClipboard()">
        <mat-icon>content_copy</mat-icon>
      </button>
  </div>
  </div>
  <div *ngIf="dataSource" class="graph">
    <canvas baseChart
      [data]="dataSource"
      [options]="chartOptions"
      [type]="graphType">
    </canvas>
  </div>
</div>

<div *ngIf="isLoading">
  <mat-progress-spinner class="spinner" mode="indeterminate" value="0" [diameter]="70"></mat-progress-spinner>
</div>
