import { ISecondaryData } from '@Models/secondary';
import { ITableColumn } from '@Models/table-column';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';

@Component({
  selector: 'app-form-secondary',
  templateUrl: './form-secondary.component.html',
  styleUrls: ['./form-secondary.component.scss']
})
export class FormSecondaryComponent implements AfterViewInit {

  @Input()
  get secondaryData(): ISecondaryData {
    return this.secondary;
  }
  set secondaryData(sec: ISecondaryData) {
    if (this.secondary) {
      this.secondary = sec;
      this.updateDataSource();
    } else {
      this.secondary = sec;
    }
  }

  @Output() dataChanged = new EventEmitter<{ column: ITableColumn, dataRow: any }>();
  @Output() openRowInForm = new EventEmitter<number>();
  @Output() newRow = new EventEmitter<any>();
  @Output() deleteRow = new EventEmitter<any>();
  @Output() openForm = new EventEmitter<{ formType: FormToOpen, column: ITableColumn, element: any, files?: any }>();

  @ViewChild(MatSort) sort = new MatSort();

  public secondary!: ISecondaryData;
  public sortDir: SortDirection = 'desc';
  public sortBy?: string;
  public showThumbnailUrl?: string;

  ngAfterViewInit() {
    this.updateDataSource();
  }

  updateDataSource() {
    if (this.secondary.dataSource) {    
      this.secondary.dataSource.sort = this.sort;

      this.secondary.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
        const column = this.secondary.columns.find(c => c.id === sortHeaderId);
        if (column) {
          if (column.type === 'choice') {
            return this.getChoiceLabel(data[sortHeaderId], column);
          } else if(column?.type === 'langStr') {
            return this.getLangStringText(data[sortHeaderId]);
          }
        }

        return data[sortHeaderId];
      }
    }
  }

  newRecord() {
    this.newRow.emit();
  }

  copyRecord(row: any) {
    const copy = JSON.parse(JSON.stringify(row));
      copy[this.secondary.primaryKey] = 0;
      this.newRow.emit(copy);   
  }

  openRecord(row: any) {
    this.openRowInForm.emit(row[this.secondary.primaryKey]);
  }

  deleteRecord(row: any) {
    this.deleteRow.emit(row);
  }

  openLangString(column: ITableColumn, element: any) {
    this.openForm.emit({
      formType: FormToOpen.LangString, 
      column, 
      element});
  }

  openLangStringSearch(column: ITableColumn, element: any) {
    this.openForm.emit({
      formType: FormToOpen.LangStringSearch, 
      column, 
      element});
  }

  openResLink(column: ITableColumn, element: any) {
    this.openForm.emit({
      formType: FormToOpen.ResLink, 
      column, 
      element});
  }

  openResLinkSearch(column: ITableColumn, element: any) {
    this.openForm.emit({
      formType: FormToOpen.ResLinkSearch, 
      column, 
      element});
  }

  createResLink(column: ITableColumn, element: any, files?: any) {
    this.openForm.emit({
      formType: FormToOpen.CreateResLink,
      column,
      element,
      files
    });
  }

  rowCellClass(column: ITableColumn, element: any): string {
    if (column.allowEdit) {
      if (element.missing && element.missing.includes(column.id)) {
        return 'missing-mat-cell'
      }
      if (element.changed && element.changed.includes(column.id)) {
        return 'changed-mat-cell';
      }
      return 'edit-mat-cell';
    }
    return 'no-edit-mat-cell';
  }

  getChoiceLabel(value: any, column: ITableColumn): string {
    return column.possibleValues.find((v) => v.value === value)?.label ?? "";
  }

  isMissingValue(element: any, col: string): boolean {
    if (element.missing && element.missing.find((e: any) => e === col)) {
      return true;
    }
    return false;
  }

  get columnArray(): string[] {
    if (this.secondary.columns) {
      let cols = this.secondary.columns.map((c: ITableColumn) => c.id);
      return ['edit', ...cols];
    }
    return [];
  }

  formDataChanged(dataRow: any, column: ITableColumn) {
    if (!dataRow.changed)
      dataRow.changed = [column.id];
    else
      dataRow.changed.push(column.id);
    
    this.dataChanged.emit({column, dataRow});
  }

  getLangStringText(str?: string): string {
    if (!str)
      return '';

    if (str && this.secondary!.languageStrings) {
      return this.secondary!.languageStrings[str];
    }

    return "Unknown" + (str ? `: ${str}` : '');
  }

  getResLinkTitle(id?: number): string {
    if (!id) return '';
    
    if (id && this.secondary!.resourceLinkInfo) {
      return this.secondary!.resourceLinkInfo[id]?.title;
    }

    return "Unknown" + (id ? `: ${id}` : '');
  }

  hasResLinkThumbnail(id?: number): boolean {
    if (!id) return false;

    if (this.secondary) {
      if (!this.secondary.resourceLinkInfo)
        return false;
      if (this.secondary.resourceLinkInfo[id].previewUrl)
        return true;
    }

    return false;  
  }

  getResLinkPreviewUrl(id?: number): string | undefined {
    if (!id) return '';

    if (this.secondary) {
      if (!this.secondary.resourceLinkInfo)
        return undefined;
      return this.secondary.resourceLinkInfo[id].previewUrl;
    } 

    return "Unknown" + (id ? `: ${id}` : '');
  }

  getResLinkOriginalUrl(id?: number): string | undefined {
    if (!id) return '';
  
    if (this.secondary) {
      if (!this.secondary.resourceLinkInfo)
        return undefined;
      return this.secondary.resourceLinkInfo[id].originalUrl;
    } 
  
    return "Unknown" + (id ? `: ${id}` : '');
  }

  showResLinkThumbnail(id?: number) {
    if (!id) {
      this.showThumbnailUrl = undefined;      
    } else if (this.secondary && this.secondary.resourceLinkInfo) {
      this.showThumbnailUrl = this.secondary.resourceLinkInfo[id].previewUrl;
    }
  }
}

export enum FormToOpen {
  ResLink,
  ResLinkSearch,
  CreateResLink,
  LangString,
  LangStringSearch
}
