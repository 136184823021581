import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ITableList } from "@Models/table-list";

export interface IListFormInput {
  title: string;
  list: ITableList;
}

@Component({
  selector: 'app-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['./edit-list.component.scss']
})
export class EditListComponent {

  constructor(public dialogRef: MatDialogRef<EditListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IListFormInput) { }

    cancel() {
      this.dialogRef.close();
    }
}
