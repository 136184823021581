import { ISecondaryData } from "./secondary";
import { ITableColumn } from "./table-column";

export interface ITableData {
  name: string;
  columns: ITableColumn[];
  rows: any[];
  primaryKey: string;
  totalRecords: number;
  allowUpdate: boolean;
  allowAdd: boolean;
  allowDelete: boolean;
  defaultNewRecord: any;
  updateOnTableChanged: string[] | undefined;
  groupBy?: string;
  useForm: boolean;
  windowSize?: string;
  secondaryControllers?: ISecondaryData[];
  formSecondaryTabs?: string[];
  languageStrings?: { [code: string]: string };
  resourceLinkInfo?: { [id: number]: IResourceLinkInfo };
  actionButtons?: { label: string, actionUrl: string }[]
}

export interface IResourceLinkInfo {
  id: number;
  title: string;
  originalUrl: string;
  previewUrl?: string;
}

export function TableDataToClipboardFormat(filteredRows: any[], displayedColumns: ITableColumn[]): string {
  let columns = '';
  let rows = '';
  displayedColumns.forEach((col: ITableColumn, index: number) => {
    if (col.visible) {
      columns += col.label;
      if (index < displayedColumns.length - 1) {
        columns += '\t';
      }
    }
  });
  rows += `${columns}\n`;

  filteredRows.forEach(row => {
    let td = '';
    displayedColumns.forEach((col: ITableColumn, index: number) => {
      if (col.visible) {
        td += row[col.id] ?? "";
        if (index < displayedColumns.length - 1) {
          td += '\t';
        }
      }
    });
    rows += `${td}\n`
  });

  return rows;
}