import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { SignedOutComponent } from './signed-out/signed-out.component';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EditListComponent } from './table-list-view/edit-list/edit-list.component';
import { ChooseListComponent } from './choose-list/choose-list.component';
import { ColumnSetupComponent } from './column-setup/column-setup.component';
import { SettingsComponent } from './settings/settings.component';
import { TableListViewComponent } from './table-list-view/table-list-view.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { TableWidgetComponent } from './widgets/table-widget/table-widget.component';
import { WeblinkWidgetComponent } from './widgets/weblink-widget/weblink-widget.component';
import { GraphWidgetComponent } from './widgets/graph-widget/graph-widget.component';
import { WidgetPageComponent } from './widgets/widget-page/widget-page.component';
import { EmptyWidgetComponent } from './widgets/empty-widget/empty-widget.component';
import { WeblinkPanelComponent } from './widgets/weblink-widget/weblink-panel/weblink-panel.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MenuComponent } from './menu/menu.component';
import { TableViewComponent } from './table-view/table-view.component';
import { SharedModule } from './shared/shared.module';
import { CustomQueriesComponent } from './custom-queries/custom-queries.component';
import { NgChartsModule } from 'ng2-charts';
import { CustomQueryParamlistComponent } from './custom-query-paramlist/custom-query-paramlist.component';
import { TextWidgetComponent } from './widgets/text-widget/text-widget.component';
import { LangStringAdminComponent } from './lang-string-admin/lang-string-admin.component';
import { ResourceListComponent } from './resource-list/resource-list.component';
import { ErrorReportComponent } from './error-report/error-report.component';


@NgModule({
  declarations: [
    AppComponent,
    ChooseListComponent,
    ColumnSetupComponent,
    ConfirmDialogComponent,
    CustomQueriesComponent,
    CustomQueryParamlistComponent,
    DashboardComponent,
    EditListComponent,
    EmptyWidgetComponent,
    GraphWidgetComponent,
    LoginComponent,
    MenuComponent,
    SettingsComponent,
    SignedOutComponent,
    TableListViewComponent,
    TableViewComponent,
    TableWidgetComponent,
    TextWidgetComponent,
    WeblinkPanelComponent,
    WeblinkWidgetComponent,
    WidgetPageComponent,
    LangStringAdminComponent,
    ResourceListComponent,
    ErrorReportComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    AppRoutingModule,
    NgChartsModule,
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
