import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IServerToken } from '@Models/server-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly SAVED_ACCESS_TOKEN = 'uncr-webapi-access';
  private readonly SAVED_REFRESH_TOKEN = 'uncr-webapi-refresh';

  private subject = new ReplaySubject<string | null>(1);

  private accessToken!: IServerToken | undefined;
  private refreshToken!: IServerToken | undefined;

  public loginUrl = 'login';
  public redirectUrl: string = '';

  constructor(private http: HttpClient) {
    const token: any = localStorage.getItem(this.SAVED_ACCESS_TOKEN);
    if (token) {
      this.accessToken = JSON.parse(token);
    }
  }

  public saveServerValidationToken(serverResponse: any) {
    const accessToken = serverResponse.access;
    const refreshToken = serverResponse.refresh;
    if (refreshToken)
      this.saveRefreshToken(refreshToken);
    
    this.saveAccessToken(accessToken);
  }

  public saveAccessToken(response: any) {
    this.accessToken = { token: response.token, validUntil: response.validUntil };
    localStorage.setItem(this.SAVED_ACCESS_TOKEN, JSON.stringify(this.accessToken));
    this.subject.next(this.accessToken.token);
  }

  private saveRefreshToken(response: any) {
    this.refreshToken = { token: response.token, validUntil: response.validUntil };
    localStorage.setItem(this.SAVED_REFRESH_TOKEN, JSON.stringify(this.refreshToken));
  }

  public signOut() {
    console.log('logging out');
    localStorage.removeItem(this.SAVED_ACCESS_TOKEN);
    localStorage.removeItem(this.SAVED_REFRESH_TOKEN);
    this.accessToken = undefined;
    this.refreshToken = undefined;
  }

  public get isAuthenticated(): boolean {
    if (!this.accessToken) {
      const storedAccessToken = localStorage.getItem(this.SAVED_ACCESS_TOKEN);
      if (storedAccessToken)
        this.accessToken = JSON.parse(storedAccessToken);
    }
    
    if (this.accessToken) {
      return true;
    }

    return false;
  }

  public get hasValidRefreshToken(): boolean {    
    if (!this.refreshToken) {
      const storedRefreshToken = localStorage.getItem(this.SAVED_REFRESH_TOKEN);
      if (storedRefreshToken) {
        this.refreshToken = JSON.parse(storedRefreshToken);
      }
    }

    if (this.refreshToken) {
      const expire = new Date(this.refreshToken.validUntil);
      return expire.getTime() > Date.now() + 1000;      
    }

    return false;
  }

  public observable(): Observable<string | null> {
    return this.subject.asObservable();
  }

  public getToken(): string | undefined {
    return this.accessToken?.token;
  }

  public refreshJwt(): Observable<any> {
    if (!this.refreshToken) {
      const storedRefreshToken = localStorage.getItem(this.SAVED_REFRESH_TOKEN);
      if (storedRefreshToken) {
        this.refreshToken = JSON.parse(storedRefreshToken);
      } else {
        throw new Error("No refresh token available.");
      }
    }

    const url = environment.jwtRefreshUrl;
    const body = `"${this.refreshToken!.token}"`;
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(url, body, options);
  }
}
