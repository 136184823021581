import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILangString } from '@Models/lang-string';
import { getLangString, ILangStringEdit, ITranslation } from '@Models/lang-string-edit';
import { ILanguage } from '@Models/language';
import { LangService } from '@Services/lang.service';

@Component({
  selector: 'lang-form',
  templateUrl: './lang-form.component.html',
  styleUrls: ['./lang-form.component.scss']
})
export class LangFormComponent implements OnInit {

  @Input() 
  get stringCode(): string { return this.code }
  set stringCode(c: string) { 
    this.code =  c;
    this.onChange();
  }

  @Input() newRecord?: boolean;
  @Output() closing: EventEmitter<ILangString | undefined> = new EventEmitter<ILangString | undefined>(); 

  public code!: string;
  public editing = false;

  public langString!: ILangStringEdit;
  public languages!: ILanguage[];
  public writingSystems!: string[];
  public selectedLanguage!: ILanguage;
  public currentTranslation!: ITranslation;
  
  private oldTranslations?: ITranslation[];
  private oldAllTranslation?: ITranslation;

  constructor(private langService: LangService) {}
  
  ngOnInit() {
    this.loadLanguages();
  }

  onChange() {
    if (this.newRecord && this.code) {
      this.langString = { 
        code: this.code, 
        useOneForAll: false, 
        translations: [] 
      };
    } else {
      this.loadLangString();
    }
  }

  loadLanguages() {
    this.langService.getLanguages().subscribe({
      next: (res) => {
        this.languages = res.sort((a, b) => 
          a.name.localeCompare(b.name) 
          - (a.default ? 100 : 0) 
          + (b.default ? 100 : 0));

        if (this.languages && this.languages.length > 0) {
          this.changeLanguage(this.languages[0]);
        }
      },
      error: (err) => console.log(err)
    });
  }

  loadLangString() {
    if (this.code) {
      this.langService.loadForEdit(this.code).subscribe({
        next: (res) => {
          this.langString = res;
          this.setCurrent();
        },
        error: (err) => console.log(err)
      });
    } else {
      // ??
    }
  }

  changeLanguage(lang: ILanguage) {
    this.selectedLanguage = lang;
    this.setCurrent();

    setTimeout(() => {
      const area = document.getElementById('memo-area');
      area?.focus();
    }, 100);
  }

  setCurrent() {
    if (this.selectedLanguage && this.langString) {
      if (this.langString.useOneForAll) {
        if (!this.langString.translations || this.langString.translations.length < 1) {
          const newAll = { language: 'all', translation: '' };
          this.langString.translations.push(newAll);
          this.currentTranslation = newAll;
        } else {
          this.currentTranslation = this.langString.translations[0];
        }
      } else {
        let strTrans = this.langString.translations.find(t => t.language === this.selectedLanguage.short);
        if (!strTrans) {
          strTrans = { language: this.selectedLanguage.short, translation: '' };
          this.langString.translations.push(strTrans);
        }

        this.currentTranslation = strTrans;
      }
    }    
  }

  useOneChanged() {
    if (this.langString.useOneForAll) {
      this.oldTranslations = this.langString.translations;
      if (this.oldAllTranslation) {
        this.langString.translations = [this.oldAllTranslation];
      } else {
        const old = this.langString.translations?.length > 0 ? 
          this.langString.translations[0].translation : '';
        this.langString.translations = [{ language: 'all', translation: old }]
      }
      this.currentTranslation = this.langString.translations[0];
    } else {
      if (this.oldTranslations) {
        this.oldAllTranslation = this.langString.translations[0];
        this.langString.translations = this.oldTranslations;
      } else {
        this.oldAllTranslation = this.langString.translations[0];
        this.langString.translations = [];
      }
      this.currentTranslation = this.langString.translations.find(t => t.language == this.selectedLanguage.short)!;
    }
  }

  onModelChange() {
    this.editing = true;
  }

  googleTranslateAll() {

  }

  save(andClose: boolean = false) {
    const defaultLang = this.languages.find(l => l.default);

    if (defaultLang) {
      let trans = this.langString.useOneForAll ?
        this.langString.translations[0] :
        this.langString.translations.find(t => t.language === defaultLang.short);

      if (!trans || !trans.translation) {
        return;
      }
    }
    
    if (this.newRecord) {
      this.langService.post(this.langString).subscribe({
        next: (res) => {
          if (res) {
            if (andClose) {
              const ls = getLangString(res);
              this.closing.emit(ls);
            } else {              
                this.langString = res;   
                this.newRecord = false;           
            }
          }
        }
      });
    } else {
      this.langService.put(this.langString).subscribe({
        next: (res) => {
          if (res) {
            if (andClose) {
              const ls = getLangString(res);
              this.closing.emit(ls);
            } else {              
                this.langString = res;              
            }
          }
        }
      });
    }

    this.editing = false;
  }

  close() {
    this.closing.emit();
  }
}
