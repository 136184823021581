import { Component } from '@angular/core';
import { IResourceFormInfo } from '@Models/resource-form-info';
import { IWidgetSetting } from '@Models/widget-settings';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss']
})
export class ResourceListComponent {
  public widgetSettings: IWidgetSetting = {
    id: 0,
    dataEndpoint: 'resourcelinks',
    label: 'Resource Links',
    position: 1,
    rowSpan: 1,
    colSpan: 1,
    widgetType: 'table',
    widgetParameters: null
  };

  public resourceInfo?: IResourceFormInfo;
  public reload = 0;

  public openRow(row: any) {
    const id = row["ResourceLinkId"] 
    this.resourceInfo = { resourceId: id, newRecord: false };
  }

  newRecord() {
    this.resourceInfo = { resourceId: 0, newRecord: true };
  }

  onFormClosing(event: any) {
    this.resourceInfo = undefined;
    if (event)
      this.reload = this.reload + 1;
  }
}
