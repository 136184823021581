import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CustomQueriesComponent } from './custom-queries/custom-queries.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { LangStringAdminComponent } from './lang-string-admin/lang-string-admin.component';
import { LoginComponent } from './login/login.component';
import { ResourceListComponent } from './resource-list/resource-list.component';
import { SettingsComponent } from './settings/settings.component';
import { SignedOutComponent } from './signed-out/signed-out.component';
import { TableListViewComponent } from './table-list-view/table-list-view.component';
import { TableViewComponent } from './table-view/table-view.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard/:id', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'tables/:id', component: TableViewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'tables', component: TableViewComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'resourcelist', component: ResourceListComponent, canActivate: [AuthGuard] },
  { path: 'languagestrings', component: LangStringAdminComponent, canActivate: [AuthGuard] },
  { path: 'lists', component: TableListViewComponent, canActivate: [AuthGuard] },
  { path: 'custom-queries', component: CustomQueriesComponent, canActivate: [AuthGuard] },
  { path: 'custom-queries/:id', component: CustomQueriesComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'signed-out', component: SignedOutComponent },
  { path: 'admin', loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule) },
  { path: '', redirectTo: '/dashboard/main', pathMatch: 'full' },
];

@NgModule({
  providers: [CanDeactivateGuard],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
