import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ICustomQuery } from '@Models/custom-query';
import { ICustomQueryListItem } from '@Models/custom-query-list-item';
import { ITableColumn } from '@Models/table-column';
import { environment } from 'src/environments/environment';
import { CustomQueryService } from '@Services/custom-query.service';

@Component({
  selector: 'app-custom-queries',
  templateUrl: './custom-queries.component.html',
  styleUrls: ['./custom-queries.component.scss']
})
export class CustomQueriesComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  public queryList: ICustomQueryListItem[] = [];
  public selectedQuery!: ICustomQuery;
  public dataArray!: any;
  public dataSource!: MatTableDataSource<any>;
  public displayedColumns!: ITableColumn[];
  public primaryKey!: string;
  public filterValue = '';
  public orderBy: string = '';
  public orderDir: SortDirection = '';

  // @ViewChild(MatSort, { read: MatSort, static: false }) sort!: MatSort;
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    private queryService: CustomQueryService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title)
  {}

  ngOnInit() {
    this.titleService.setTitle(environment.AppName + ' - Custom Queries');

    this.getQueryList();

    this.subscriptions.add(this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        const id = params.get("id");
        if (id && (!this.selectedQuery || this.selectedQuery.id.toString() != id)) {
          this.getQueryInfo(id);
        }
      }
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions)
      this.subscriptions.unsubscribe();
  }

  getQueryList() {
    this.subscriptions.add(this.queryService.getQueryList().subscribe({
      next: (res: ICustomQueryListItem[]) => {
        this.queryList = res.sort((a, b) => a.label.localeCompare(b.label));
      },
      error: err => console.log(err)
    }));
  }

  getQueryInfo(id: string) {
    this.subscriptions.add(this.queryService.getQueryInfo(id).subscribe({
      next: (res: ICustomQuery) => {
        this.selectedQuery = res;
        if (!res.parameters || res.parameters.length === 0) {
          this.loadData();
        } else {
          let allSet = true;
          this.selectedQuery.parameters.forEach(p => {
            const value = this.route.snapshot.queryParamMap.get(p.name);
            if (value != null && value != '')
              p.value = value;
            else if (p.type != 'checkbox')
              allSet = false;
            else {
              p.value = false;
            }
          });

          if (allSet)
            this.loadData();
        }
      }
    }));
  }

  select(query: ICustomQueryListItem) {
    this.router.navigate([`/custom-queries/${query.id}`]);
  }

  unselect() {
    this.router.navigate([`/custom-queries`]);
  }

  paramChanged() {
    let allSet = true;
    let params: any = {};
    this.selectedQuery.parameters.forEach(p => {
      if (p.type != 'checkbox' && (p.value == null || p.value == '')) {
        allSet = false;
      } else {
        params[p.name] = p.value;
      }
    });

    if (allSet) {
      this.loadData();
    }

    this.router.navigate([`/custom-queries/${this.selectedQuery.id}`], { queryParams: params });
  }

  loadData() {
    let queryString = '';
    if (this.selectedQuery.parameters?.length > 0) {
      this.selectedQuery.parameters.forEach(p => {
        queryString += `${p.name}=${p.value}&`;
      });
      queryString = queryString.substring(0, queryString.length - 1);
    }

    this.subscriptions.add(this.queryService
      .runQuery(this.selectedQuery.id, queryString)
      .subscribe({
        next: (res) => {
          this.dataArray = res.rows;
          this.displayedColumns = res.columns;
          this.dataSource = new MatTableDataSource(this.dataArray);
          this.dataSource.sort = this.sort;
        }
      }));
  }

  private timer: any;
  filterChanged() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.dataSource.filter = this.filterValue.trim().toLowerCase();
    }, 1000);
  }

  clearFilter() {
    this.filterValue = '';
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  columnArray(): string[] {
    if (this.displayedColumns) {
      return this.displayedColumns.map((c) => c.id);
    } else {
      return [];
    }
  }
}
