<app-custom-query-paramlist *ngIf="parameters && parameters.length > 0"
      [parameters]="parameters"
      (runClicked)="runCustomQueries()">
    </app-custom-query-paramlist>
<div [class]="parameters && parameters.length > 0 ? 'list-w-params' : 'list'">
  <mat-grid-list *ngIf="tiles" [cols]="cols" [rowHeight]="100 / rows + '%'">
    <mat-grid-tile class="cell" *ngFor="let widget of widgetList" [colspan]="widget.colSpan" [rowspan]="widget.rowSpan">
      <ng-container [ngSwitch]="widget.widgetType">
        <app-table-widget
          *ngSwitchCase="'table'"
          class="widget"
          [widget]="widget"
          (showWidgetClicked)="openWidgetSettings(widget.id)">
        </app-table-widget>
        <app-table-widget
          *ngSwitchCase="'cq-table'"
          class="widget"
          [widget]="widget"
          [customQueryString]="customQueryString"
          (showWidgetClicked)="openWidgetSettings(widget.id)">
        </app-table-widget>
        <app-weblink-widget
          *ngSwitchCase="'weblink'"
          class="widget">
        </app-weblink-widget>
        <app-graph-widget
          *ngSwitchCase="'graph'"
          class="widget"
          [widget]="widget"
          (showWidgetClicked)="openWidgetSettings(widget.id)">
        </app-graph-widget>
        <app-graph-widget
          *ngSwitchCase="'cq-graph'"
          class="widget"
          [customQueryString]="customQueryString"
          [widget]="widget"
          (showWidgetClicked)="openWidgetSettings(widget.id)">
        </app-graph-widget>
        <app-text-widget
          *ngSwitchCase="'text'"
          class="widget"
          [label]="widget.label"
          [text]="widget.widgetParameters"
          (showWidgetClicked)="openWidgetSettings(widget.id)">
        </app-text-widget>
        <app-empty-widget 
          *ngSwitchDefault
          (showWidgetClicked)="openWidgetSettings(widget.id)"></app-empty-widget>
      </ng-container>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<app-table-form 
  *ngIf="formData" 
  [formData]="formData"
  (closing)="formClosing()">
</app-table-form>