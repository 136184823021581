import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private http: HttpClient, private router: Router, private ngZone: NgZone) { }

  ngOnInit(): void {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true
    });
    // @ts-ignore
    google.accounts.id.renderButton(
    // @ts-ignore
    document.getElementById("google-button"),
      { theme: "outline", size: "large", width: "100%" }
    );
    // @ts-ignore
    google.accounts.id.prompt((notification: PromptMomentNotification) => {});
  }

  public async handleCredentialResponse(response: any) {
    // Response from Google.
    if (response?.credential) {
      const url = environment.jwtValidationUrl;
      const body = `"${response.credential}"`;
      const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

      await this.http.post(url, body, options).subscribe({
        next: (res: any) => {
          this.authService.saveServerValidationToken(res);
          this.ngZone.run(() => {
            this.router.navigate([this.authService.redirectUrl]);
          });
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else 
      console.log("No credential Response")
  }
  
}
