<div mat-dialog-title>
  Choose a List
</div>
<div mat-dialog-content>
  <mat-selection-list [multiple]="false">
    <mat-list-option *ngFor="let list of data" [mat-dialog-close]="list.id">
      {{list.name}}
    </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</div>
