import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICustomQuery } from '@Models/custom-query';
import { ICustomQueryListItem } from '@Models/custom-query-list-item';
import { ITableData } from '@Models/table-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomQueryService {

  constructor(private http: HttpClient) { }

  getQueryList() : Observable<ICustomQueryListItem[]> {
    return this.http.get<ICustomQueryListItem[]>(environment.apiUrl + 'query/list');
  }

  getQueryInfo(id: string) : Observable<ICustomQuery> {
    return this.http.get<ICustomQuery>(`${environment.apiUrl}query/${id}`);
  }

  runQuery(id: number | string, queryStr: string) : Observable<ITableData> {
    const url = `${environment.apiUrl}query/run/${id}?${queryStr}`;
    return this.http.get<ITableData>(url);
  }
}
