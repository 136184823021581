<div class="header-area">
    <div>
        <h1>{{label}}</h1>
    </div>
    <div *ngIf="isAdmin" class="button-area">
        <button mat-icon-button class="header-button" (click)="openWidgetSettings()">
          <mat-icon>design_services</mat-icon>
        </button>
    </div>
</div>
<div class="text-area">
    <textarea
        readonly
        spellcheck="false"
        wrap="off"
    >{{text}}</textarea>
</div>