<div class="table-menu-area">
  <div class="left-menu-area">
    <mat-form-field class="table-menu" appearance="fill">
      <mat-label>Choose a table</mat-label>
      <mat-select [(value)]="table" (selectionChange)="tableChanged($event.value)">
        <mat-option *ngFor="let tab of tableInfoArray" [value]="tab.url">{{tab.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="table-button-left" mat-raised-button *ngIf="table" (click)="refreshData()">
      <ng-container *ngIf="!currentList">Refresh</ng-container>
      <ng-container *ngIf="currentList">Close List</ng-container>
    </button>
    <button class="table-button-left" mat-raised-button *ngIf="allowAdd && !currentList" (click)="addRecord()">Add Record</button>
    <button class="table-button-left" mat-raised-button *ngIf="userIsEditing" color="warn" (click)="saveData()">Save changes</button>
    <button class="table-button-left" mat-raised-button *ngIf="table && (primaryKey || allowDelete)" (click)="toggleSelect()">Select</button>
    <button class="table-button-left" mat-raised-button *ngIf="primaryKey && !currentList && selection.selected.length > 0" [matMenuTriggerFor]="listMenu">Add to List</button>
    <mat-menu #listMenu="matMenu">
      <button mat-menu-item (click)="createTableList()">New list</button>
      <button mat-menu-item (click)="showChooseList(true)">Existing list</button>
    </mat-menu>
    <button class="table-button-left" mat-raised-button *ngIf="currentList && selection.selected.length > 0" (click)="removeSelectedFromList()">Remove from List</button>
    <button class="table-button-left" mat-raised-button *ngIf="selection.selected.length > 0 && allowDelete" color="warn" (click)="deleteSelected()">Delete Selected</button>
  </div>

  <div class="right-menu-area">
    <button class="table-button-right" mat-raised-button *ngIf="table" (click)="showColumnDialog()">Change Columns</button>
    <button class="table-button-right" mat-raised-button *ngIf="hasArtNr" (click)="filterByArticles()">Article Filter</button>
    <button class="table-button-right" mat-raised-button *ngIf="table && primaryKey" (click)="showChooseList()">Open List</button>

    <mat-form-field *ngIf="table" appearance="standard" class="filter-area">
      <mat-label>Filter</mat-label>
      <input matInput type="text" [(ngModel)]="filterValue" (keyup)="filterChanged()">
      <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<div>
  <h3 *ngIf="currentListName">Showing List: "{{ currentListName }}"</h3>
</div>

<div class="table-area">
  <table #mainTable mat-table [dataSource]="dataSource" matSort matSortStart="desc" [matSortActive]="sortBy" [matSortDirection]="sortDir" (matSortChange)="sortChanged($event)">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" [sticky]="true">
      <th mat-header-cell *matHeaderCellDef class="select-header-cell">
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    
    <ng-container *ngIf="useForm" matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef class="edit-button-header-cell"></th>
      <td mat-cell *matCellDef="let row" class="edit-button-row-cell">
        <button mat-icon-button (click)="openForm(row)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="column.id" *ngFor="let column of displayedColumns; let i = index" [sticky]="i < stickyColumns">
      <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header sortActionDescription="Sort by {{column.label}}">
        <a *ngIf="!(column.required && addingNewRows)">{{column.label}}</a>
        <a *ngIf="column.required && addingNewRows">{{column.label}}*</a>
      </th>

      <td mat-cell [class]="rowCellClass(column, element)" *matCellDef="let element" (click)="setEditMemo(element, column)" (dblclick)="openForm(element)">
        <ng-container *ngIf="useForm || !allowUpdate || !column.allowEdit" class="no-edit-mat-cell">
          
          <ng-container *ngIf="column.linkedPages; else noLinkData">
            <div class="clickable-text" *ngIf="column.linkedPages.length == 1" (click)="linkedPageClicked(column.linkedPages[0], element[column.id])">
              {{element ? (element[column.id]) : ""}}
            </div>
            <div class="clickable-text" *ngIf="column.linkedPages.length > 1" [matMenuTriggerFor]="linkMenu">
              {{element ? (element[column.id]) : ""}}
            </div>
            <mat-menu #linkMenu="matMenu" >
              <ng-template matMenuContent let-name="linkMenu">
                <button mat-menu-item *ngFor="let link of column.linkedPages" (click)="linkedPageClicked(link, element[column.id])">
                  {{link.label}}
                </button>
              </ng-template>
            </mat-menu>
          </ng-container>
  
          <ng-template #noLinkData>
            <ng-container [ngSwitch]="column.type">
              <ng-container *ngSwitchCase="'url'">
                <a [attr.href]="element[column.id]">{{element[column.id]}}</a>
              </ng-container>
              <ng-container *ngSwitchCase="'choice'">
                {{ getChoiceLabel(element[column.id], column) }}
              </ng-container>
              <ng-container *ngSwitchCase="'langStr'">
                {{ getLangStringText(element[column.id]) }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{element ? (element[column.id] | dataFormat : column.type : column.customFormat) : ""}}
              </ng-container>
            </ng-container>
          </ng-template>
          
        </ng-container>

        <ng-container *ngIf="!useForm && allowUpdate && column.allowEdit" [ngSwitch]="column.type">
          <ng-container *ngSwitchCase="'choice'">
            <mat-form-field>
              <mat-label class="required-label"><a *ngIf="isMissingValue(element, column.id)">(Required)</a></mat-label>
              <mat-select [(ngModel)]="element[column.id]" (selectionChange)="dataChanged(element, column.id)">
                <mat-option *ngFor="let val of column.possibleValues" [value]="val.value">{{val.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="'checkbox'">
            <mat-checkbox [(ngModel)]="element[column.id]" (change)="dataChanged(element, column.id)"></mat-checkbox>
          </ng-container>
          <ng-container *ngSwitchCase="'textarea'">
            <div class="textarea-field">
              {{element ? element[column.id] : ""}}
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'langStr'">
            <div (click)="openLangStringForm(column, element)">
              <mat-icon class="field-icon">translate</mat-icon>
              {{ getLangStringText(element[column.id]) }}
            </div>
        </ng-container>
          <ng-container *ngSwitchDefault>
            <mat-form-field class="form-field">
              <mat-label class="required-label"><a *ngIf="isMissingValue(element, column.id)">(Required)</a></mat-label>
              <input [type]="column.type" matInput [(ngModel)]="element[column.id]" (keydown)="dataChanged(element, column.id)">
            </mat-form-field>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnArray(); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnArray();"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="1">
        <p *ngIf="dataSource.data.length > 0">No data matching filter "{{filterValue}}"</p>
        <p *ngIf="dataSource.data.length == 0">No data</p>
      </td>
    </tr>
  </table>
</div>

<memo-card 
  *ngIf="editMemo" 
  [editMemo]="editMemo" 
  (dataChanged)="onMemoChange($event)">
</memo-card>

<app-table-form 
  *ngIf="formData" 
  [formData]="formData"
  (closing)="formClosing($event)">
</app-table-form>

<div *ngIf="isLoading">
  <mat-progress-spinner class="spinner" mode="indeterminate" value="0" [diameter]="70"></mat-progress-spinner>
</div>
