import { Component, OnInit } from '@angular/core';
import { WidgetService } from '@Services/widget.service';
import { IWebLink } from '@Models/web-link';

@Component({
  selector: 'app-weblink-widget',
  templateUrl: './weblink-widget.component.html',
  styleUrls: ['./weblink-widget.component.scss']
})
export class WeblinkWidgetComponent implements OnInit {

  private weblinkList!: IWebLink[];
  public visableList!: IWebLink[];
  public filterValue: string = '';
  public expandAll = false;

  constructor(private widgetService: WidgetService) { }

  ngOnInit() {
    this.widgetService.getWeblinkTree().subscribe({
      next: (res: any) => {
        
        this.weblinkList = res.sort(this.compareWebLinks);
        this.visableList = res.sort(this.compareWebLinks);
      },
      error: err => console.log(err)
    })
  }

  private timer: any;
  filterChanged() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.applyFilter();
    }, 1000);
  }

  applyFilter() {
    if (this.filterValue)
    {
      this.visableList = this.filterWebLink(this.filterValue.toLowerCase(), this.weblinkList);
      this.expandAll = true;
    }
    else
    {
      this.visableList = this.weblinkList;
      this.expandAll = false;
    }
  }

  clearFilter() {
    this.filterValue = '';
    this.visableList = this.weblinkList;
    this.expandAll = false;
  }

  private filterWebLink(word: string, list: IWebLink[]): IWebLink[] {
    let newList: IWebLink[] = [];
    list.forEach(l => {
      if (l.children) {
        let filteredChildren = [];
        filteredChildren = this.filterWebLink(word, l.children);
        if (filteredChildren.length > 0) {
          let newWebLink: IWebLink = { label: l.label, children: filteredChildren, url: null };
          newList.push(newWebLink);
        }
      } else {
        if (l.url?.toLowerCase().includes(word) || l.label.toLowerCase().includes(word)) {
          newList.push({ url: l.url, label: l.label, children: null });
        }
      }
    });

    return newList.sort(this.compareWebLinks);
  }

  get folderNodes(): IWebLink[] {
    if (this.visableList)
      return this.visableList.filter(l => !l.url);
    return [];
  }

  get linkNodes(): IWebLink[] {
    if (this.visableList)
      return this.visableList.filter(l => l.url != null);
    return [];
  }

  private compareWebLinks(a: IWebLink, b: IWebLink) {
    return a.label.localeCompare(b.label, 'sv-SE');
  }
}
