<div class="report-background">
    <mat-card class="card">
        <mat-card-header>
            <h1>Error Report</h1>
        </mat-card-header>
        <mat-card-content *ngIf="data" class="content">
            <div>
                <div class="area-div">
                    <p>Summary</p>
                    <input matInput
                        id="summary-input"
                        class="input-field"
                        [readonly]="(errorReportId ?? 0) > 0"
                        [(ngModel)]="data['summary']">
                </div>
                <div class="area-div">
                    <p>The expected behavior and actual behavior.</p>
                    <textarea
                    id="behavior-input"
                    class="memo-input"
                    [readonly]="(errorReportId ?? 0) > 0"
                    cols="80"
                    rows="10"
                    spellcheck="true"
                    wrap="off"
                    [(ngModel)]="data['behavior']"></textarea>
                </div>
                <div class="area-div">            
                    <p>A detailed description of the problem, including steps to reproduce it.</p>
                    <textarea
                    id="description-input"
                    class="memo-input"
                    [readonly]="(errorReportId ?? 0) > 0"
                    cols="80"
                    rows="10"
                    spellcheck="true"
                    wrap="off"
                    [(ngModel)]="data['description']"></textarea>
                </div>
                <div>
                    <!-- Files -->
                </div>
            </div>
            <div *ngIf="errorReport" class="support-area">
                <div>
                    <p>Status</p>
                    <input matInput>
                </div>
                <div>
                    Level: {{ errorReport.level }}
                    <button mat-button class="escalate-button" [disabled]="cannotEscalate" (click)="escalate()">Escalate</button>
                </div>
                <div>
                    <p>Comments</p>
                    <textarea
                    id="comment-input"
                    class="memo-input"
                    cols="80"
                    rows="20"
                    spellcheck="true"
                    wrap="off"
                    (keydown)="editing = true"
                    [(ngModel)]="errorReport.handlerComments"></textarea>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions class="action-area">
            <button mat-button [disabled]="saving" class="action-button" (click)="close()">Cancel</button>
            <button mat-button *ngIf="!errorReportId" [disabled]="saving" class="action-button" (click)="send()">Send</button>
            <button mat-button *ngIf="errorReportId" [disabled]="saving || !editing" class="action-button" (click)="saveAndClose()">Save & Close</button>
        </mat-card-actions>
    </mat-card>
</div>