<div class="area-around">
    <div class="form-area">
        <div>
            <div class="header">
                <h1>Resource Link</h1>
            </div>
            <div class="center">
                <div class="main-area">
                    <div class="field-area" *ngIf="resourceLink">
                        <div>
                            <div>
                                <mat-label>Type*</mat-label>
                            </div>
                            <div class="choice-area">
                                <mat-select 
                                    [class]="fieldClass('resourceLinkTypeId')" 
                                    [(ngModel)]="resourceLink.resourceLinkTypeId"
                                    (selectionChange)="formDataChanged('resourceLinkTypeId')">
                                        <mat-option 
                                            *ngFor="let opt of resourceLink.typeChoices" 
                                            [value]="opt.value">
                                                {{opt.label}}
                                        </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <mat-label>Title*</mat-label>
                            </div>
                                <input 
                                matInput 
                                type="text"
                                [class]="fieldClass('title')"
                                [(ngModel)]="resourceLink.title"
                                (change)="formDataChanged('title')">
                        </div>
                        <div>
                            <div>
                                <mat-label>Tags</mat-label>
                            </div>
                            <input 
                                matInput 
                                type="text"
                                [class]="fieldClass('description')"
                                [(ngModel)]="resourceLink.description"
                                (change)="formDataChanged('description')">
                        </div>
                        <div>                        
                            <div>
                                <div>
                                    <mat-label>Thumnail</mat-label>
                                </div>
                                <div class="flex-row">
                                    <input 
                                        matInput
                                        appDragDropFileUpload 
                                        [class]="thumbnailClass"
                                        type="text" disabled="true" 
                                        [value]="resourceLink.thumbnailTitle"
                                        (fileDropped)="createResLinkForThumbnail($event)">
                                    <div *ngIf="resourceLink?.thumbnailUrl" class="resource-preview">
                                        <img class="preview-image" [src]="resourceLink.thumbnailUrl" />
                                    </div>
                                    <mat-icon *ngIf="resourceLink.thumbnailId" (click)="editThumbnailResLink()" class="res-icon">edit</mat-icon>
                                    <mat-icon *ngIf="!resourceLink.thumbnailId" (click)="createResLinkForThumbnail()" class="res-icon">upload</mat-icon>
                                    <mat-icon (click)="openResLinkSearch()" class="res-icon">plagiarism</mat-icon>                            
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="resourceLink?.previewUrl" class="file-area">
                        <div class="img-area">
                            <img [src]="resourceLink!.previewUrl" />
                        </div>
                    </div>
                    <div
                        appDragDropFileUpload 
                        class="dropzone"
                        (fileDropped)="onFileDropped($event)">
                            <input
                                type="file" 
                                #fileDropRef
                                id="fileDropRef"
                                (change)="fileSelected($event)" />
                            <mat-icon fontIcon="upload"></mat-icon>
                            <h3>Drag and drop file here or</h3>
                            <label for="fileDropRef">Browse for file</label>
                            <div *ngIf="newFile">
                                <p>File choosen:</p>
                                <p>{{newFile.name}}</p>
                            </div>
                    </div>                    
                </div>
                <div *ngIf="resourceLink?.fileLinks" class="url-list-area">
                    <div *ngFor="let file of resourceLink!.fileLinks">
                        - <a [href]="file.url" target="_blank">{{file.url}}</a>
                        : <a>[Version: {{file.version}}]</a>
                        <a *ngIf="file.original"> (Original)</a>
                        <a *ngIf="file.preview"> (Preview)</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-area">
            <button 
                mat-raised-button
                (click)="close()">
                    Close
            </button>
            <button 
                mat-raised-button
                [disabled]="!editing"
                (click)="save(true)">
                    Save & Close
            </button>
            <button 
                mat-raised-button
                [disabled]="!editing"
                (click)="save()">
                    Save
            </button>
        </div>
    </div>
</div>

<resource-form
    *ngIf="editThumbnailInfo"
    [resourceInfo]="editThumbnailInfo"
    (onFormClosing)="onEditThumbnailClosing($event)">
</resource-form>

<resource-search 
    *ngIf="searchThumbnailLink"
    title="Thumnail"
    (closing)="onResLinkSearchClosing($event)">
</resource-search>

<div *ngIf="saving" class="save-spinner">
    <mat-progress-spinner
        class="spinner" 
        mode="indeterminate" 
        value="0" 
        [diameter]="70">
    </mat-progress-spinner>
</div>