import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ITableList } from "@Models/table-list";

@Component({
  selector: 'app-choose-list',
  templateUrl: './choose-list.component.html',
  styleUrls: ['./choose-list.component.scss']
})
export class ChooseListComponent {
  constructor(public dialogRef: MatDialogRef<ChooseListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITableList[]) { }

    cancel() {
      this.dialogRef.close();
    }
}
