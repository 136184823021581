import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from '@Services/app.service';

@Component({
  selector: 'app-empty-widget',
  templateUrl: './empty-widget.component.html',
  styleUrls: ['./empty-widget.component.scss']
})
export class EmptyWidgetComponent implements OnInit {
  @Output() showWidgetClicked: EventEmitter<any> = new EventEmitter<any>();

  public isAdmin = false;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.appService.getMe().subscribe({
      next: (res) => this.isAdmin = res?.isAdministrator ?? false 
    });
  }

  public openWidgetSettings() {
    this.showWidgetClicked.emit();
  }

}
