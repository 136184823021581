<div class="list-view">
  <div class="table-area">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="table">
        <th mat-header-cell class="big-header-cell" mat-sort-header *matHeaderCellDef>Table</th>
        <td mat-cell *matCellDef="let element">{{element.table}}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell class="big-header-cell" mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      <ng-container matColumnDef="private">
        <th mat-header-cell class="short-header-cell" mat-sort-header *matHeaderCellDef>Private</th>
        <td mat-cell *matCellDef="let element">{{element.private}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell class="big-header-cell" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="action-bar">
            <button mat-icon-button color="primary" aria-label="Open" (click)="open(element)">
              <mat-icon>open_in_new</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="Edit" (click)="edit(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" aria-label="Delete" (click)="delete(element)">
              <mat-icon>delete</mat-icon>
            </button>
        </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
    </table>
  </div>

  <div class="action-area">
    <mat-form-field appearance="standard" class="filter-area">
      <mat-label>Filter</mat-label>
      <input matInput type="text" [(ngModel)]="filterValue" (keyup)="filterChanged()">
      <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

