import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITableData } from '@Models/table-data';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IWebLink } from '@Models/web-link';
import { IListedObject } from '@Models/listed-object';
import { IDashboardSettings } from '@Models/dashboard-settings';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private http: HttpClient) {}

  getTableInfo() : Observable<IListedObject> {
    return this.http.get<IListedObject>(environment.apiUrl + 'entity');
  }

  getTable(endpoint: string) : Observable<ITableData> {
    let realEndpoint = endpoint.replace('-', '/');
    let url = environment.apiUrl + realEndpoint;
    return this.http.get<ITableData>(url);
  }

  getWeblinkTree() : Observable<IWebLink[]> {
    let url = environment.apiUrl + 'weblinks';
    return this.http.get<IWebLink[]>(url);
  }

  getWidgetPageSetup(pageName: string): Observable<IDashboardSettings> {
    let url = environment.apiUrl + 'dashboard/' + pageName;
    return this.http.get<IDashboardSettings>(url);
  }
}
