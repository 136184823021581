import { Pipe, PipeTransform } from '@angular/core';
import { ICustomFormat } from '@Models/table-column';

@Pipe({name: 'dataFormat'})
export class DataFormatPipe implements PipeTransform {
  transform(value: any, type = 'string', customFormat: ICustomFormat): string {
    if (!value)
      return value;

    switch (type.toLowerCase()) {
      case 'datetime': {
        let date = new Date(value + 'Z');
        let localDate = date.toLocaleString();
        return localDate.substring(0, localDate.length - 3);
      }
      case 'datetimes': 
      case 'datetime-local': {
        let date = new Date(value + 'Z');
        return date.toLocaleString();
      }
      case 'date': {
        let date = new Date(value + 'Z');
        return date.toLocaleDateString();
      }
      case 'time': {
        let date = new Date(value + 'Z');
        let localDate = date.toLocaleTimeString();
        return localDate.substring(0, localDate.length - 3);
      }
      case 'times': {
        let date = new Date(value + 'Z');
        return date.toLocaleString();
      }
      case 'number': {
        return this.formatNumber(value, customFormat);
      }
      case 'string': {
        return this.formatString(value, customFormat);
      }
    }
    return value;
  }

  private formatNumber(value: number, customFormat: ICustomFormat): string {
    let strValue = value.toString();
    if (customFormat) {
      if (customFormat.decimals != undefined) {
        strValue = value.toFixed(customFormat.decimals);
      }
      if (customFormat.thousandSep) {
        strValue = strValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }      
    }

    return this.formatString(strValue, customFormat);
  }

  private formatString(strValue: string, customFormat: ICustomFormat): string {
    if (customFormat.prefix) {
      strValue = customFormat.prefix + strValue;
    }
    if (customFormat.postfix) {
      strValue += customFormat.postfix;
    }
    return strValue;
  }
}
