<div mat-dialog-title>
  <p>Change Columns</p>
</div>
<div mat-dialog-content>
  <div>
    <table #columnTable mat-table [dataSource]="columnDataSource">
      <ng-container matColumnDef="sticky">
        <th mat-header-cell class="sticky-column" *matHeaderCellDef>Sticky</th>
        <td mat-cell *matCellDef="let element; let i = index">
        <mat-radio-group [(ngModel)]="output.stickyColumns" (click)="radioSelected(i + 1)">
          <mat-radio-button [value]="i + 1"></mat-radio-button>
        </mat-radio-group>
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Position</th>
        <td mat-cell *matCellDef="let element"> {{element.position}}</td>
      </ng-container>
      <ng-container matColumnDef="label">
        <th mat-header-cell class="label-column" *matHeaderCellDef>Label</th>
        <td mat-cell *matCellDef="let element"> {{element.label}}</td>
      </ng-container>
      <ng-container matColumnDef="visible">
        <th mat-header-cell *matHeaderCellDef>visible</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.visible"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.position > minColumnPosition" aria-hidden="false" aria-label="Move up" (click)="moveColumnUp(element)">arrow_upward</mat-icon>
          <mat-icon *ngIf="element.position <= minColumnPosition" aria-hidden="false" aria-label="Move up">expand_less</mat-icon>
          <mat-icon *ngIf="element.position < maxColumnPosition" aria-hidden="false" aria-label="Move down" (click)="moveColumnDown(element)">arrow_downward</mat-icon>
          <mat-icon *ngIf="element.position >= maxColumnPosition" aria-hidden="false" aria-label="Move down">expand_more</mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnTableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: columnTableColumns;"></tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="resetColumns()">Reset</button>
  <button mat-button (click)="accept()" cdkFocusInitial>Accept</button>
  <button *ngIf="data.isList" mat-button (click)="saveListColumnSetup()">Save to List</button>
</div>
