import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomQueryService } from '@Services/custom-query.service';
import { SignalRService } from '@Services/signal-r.service';
import { TableService } from '@Services/table.service';
import { ILinkedPages, ITableColumn } from '@Models/table-column';
import { TableDataToClipboardFormat } from '@Models/table-data';
import { IWidgetSetting, IWidgetTableParams } from '@Models/widget-settings';
import { AppService } from '@Services/app.service';
import { IViewData } from '@Models/view-data';
import { IFormData } from '@Models/form-data';

@Component({
  selector: 'app-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.scss']
})
export class TableWidgetComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  get widget(): IWidgetSetting | undefined { return this.widgetSettings; }
  set widget(w: IWidgetSetting | undefined) { 
    this.widgetSettings = w;
    this.loadWidgetParams();
    if (this.widgetSettings && this.cqParams) {
      this.loadCustomQueryData();
    }
  }

  @Input()
  get customQueryString(): string { return this.cqParams; }
  set customQueryString(params: string) {
    this.cqParams = params;
    if (this.cqParams && this.widgetSettings) {
      this.loadCustomQueryData();
    }
  }

  @Input()
  set triggerDataReload(num: number) {
    if (num)
      this.refresh(); 
  }

  @Input() showWidgetEditForAdmin: boolean = true;
  @Input() showCopyToClipboard: boolean = true;
  @Input() parentHandlesRowTriggered: boolean = false;
  @Input() parentHandlesAdd: boolean = false;
  @Output() onRowTriggered: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() showWidgetClicked: EventEmitter<any> = new EventEmitter<any>();
  
  public widgetSettings?: IWidgetSetting;
  private cqParams!: string;
  private viewData!: IViewData;
  private dataArray!: any[];
  private updateOnTableChanged: string[] = [];
  private signalRsub!: Subscription;
  public groupBy?: string;
  public dataSource!: MatTableDataSource<any>;
  public displayedColumns!: ITableColumn[];
  public orderBy: string = '';
  public orderDir: SortDirection = '';
  public filterValue: string = '';
  public isLoading!: boolean;
  public isAdmin = false;

  @ViewChild(MatSort, { read: MatSort, static: false }) sort!: MatSort;

  constructor(
    private tableService: TableService,
    private router: Router,    
    private appService: AppService,
    private queryService: CustomQueryService,
    private signalR: SignalRService) { }
    
    public formData: IFormData | undefined;
    private formEndpoint?: string;
    public formAllowAdd = false;
    public errorReportId?: number;

  ngAfterViewInit() {
    if (this.widgetSettings?.dataEndpoint && this.widgetSettings.widgetType === 'table') {
      this.loadTable();
    }
  }

  ngOnInit() {
    this.appService.getMe().subscribe({
      next: (res) => this.isAdmin = res?.isAdministrator ?? false 
    });
  }

  ngOnDestroy() {
    if (this.signalRsub) {
      this.signalRsub.unsubscribe();
    }
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
  }

  private loadWidgetParams() {
    if (this.widgetSettings?.widgetParameters && this.widgetSettings.widgetParameters.trim().startsWith('{'))
    {
      const par: IWidgetTableParams = JSON.parse(this.widgetSettings.widgetParameters);
      
      if (par.orderBy)
      this.orderBy = par.orderBy;
      if (par.orderDir)
        this.orderDir = par.orderDir;
      if (par.reload) {
        this.updateWithTimer(par.reload);
      }
    }
  }

  private subscribeToSignalR() {
    if (!this.signalRsub) {
      this.signalRsub = this.signalR.subscribeToUpdates().subscribe({
        next: (changed: string) => {
          if (this.updateOnTableChanged.includes(changed)) {
            this.refresh();
          }         
        }
      });
    }
  }

  private loadTable() {
    this.dataArray = [];
    setTimeout(()=> {
      this.isLoading = true;
    }, 0);

    this.tableService.get(this.widgetSettings!.dataEndpoint).subscribe({
      next: (res: IViewData) => {
        this.handleData(res);
        this.isLoading = false
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.isLoading = false
      }
    });
  }

  private loadCustomQueryData() {
    this.dataSource = new MatTableDataSource();
    this.isLoading = true;
    
    this.queryService
      .runQuery(this.widgetSettings!.dataEndpoint, this.cqParams)
      .subscribe({
        next: (res) => {
          this.handleData(res);
          this.isLoading = false
        },
        error: (err) => {
          console.log(err)
          this.isLoading = false
        }
      });
  }

  private handleData(res: IViewData) {
    if (res.rows) {
      this.cleanData(res);
      this.viewData = res;
      this.groupBy = res.groupBy;
            
      if (this.groupBy) {
        this.dataArray = this.createGroupedDataArray(this.groupBy, res.rows)
      } else this.dataArray = res.rows;

      if (res.updateOnTableChanged) {
        this.updateOnTableChanged = res.updateOnTableChanged;
        this.subscribeToSignalR();
      } else {
        this.updateOnTableChanged = [];
      }
    } else {
      this.dataArray = [];
    }

    this.displayedColumns = res.columns;
    this.dataSource = new MatTableDataSource(this.dataArray);
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filterValue;

    this.prepareFormInfo(res);
  }

  private prepareFormInfo(res: IViewData) {
    if (res.formInfo) {
      if (res.formInfo.trim().startsWith('{')) {
        const formInfo = JSON.parse(res.formInfo);
        this.formEndpoint = formInfo.endpoint;
        this.formAllowAdd = formInfo.allowAdd;
      } else {
        this.formEndpoint = res.formInfo;
      }
    }
  }

  private cleanData(data: IViewData) {
    data.columns.forEach((c) => {
      if (c.type.startsWith('date')) {
        data.rows.forEach((r: any) => {
          if (r[c.id]) r[c.id] = r[c.id].split('.')[0];
        });
      } else if (c.type === 'choice') {
        data.rows.forEach((row: any) => {
          if (!row['#choices']) 
            row['#choices'] = this.getChoiceLabel(row[c.id], c).toLocaleLowerCase();
          else
            row['#choices'] += this.getChoiceLabel(row[c.id], c).toLocaleLowerCase();
        });
      }
    });
  }

  createGroupedDataArray(groupByColumn: string, origRows: any[]): any[] {
    const dataArray: any[] = [];
    let workingGroup: any = undefined;
    const rows: any = JSON.parse(JSON.stringify(origRows));
    rows.sort((a: any,b: any) => a.toString().localeCompare(b.toString()))
      .forEach((row: any) => {
        if (row[groupByColumn] !== workingGroup) {
          workingGroup = row[groupByColumn];
          const newRow: any = { groupBy: true };
          newRow[groupByColumn] = workingGroup;
          dataArray.push(newRow);
        } 
        
        row[groupByColumn] = undefined;
        dataArray.push(row);
      }
    );

    return dataArray;
  }

  public getTdClass(element: any): string {
    if (element.groupBy)
      return "td-groupby";
    else
      return "td-cell";
  }

  public columnArray(): string[] {
    if (this.displayedColumns) {
      let cols = this.displayedColumns
        .filter((c) => c.visible)
        .map((c) => c.id);
      return cols;
    } else {
      return [];
    }
  }

  public refresh() {
    if (this.widgetSettings) {
      if (this.widgetSettings.widgetType === 'cq-table') {
        if (this.cqParams) {
          this.loadCustomQueryData();
        }
      } else {
        this.loadTable();
      }      
    }    
  }

  private timer: any;
  public filterChanged() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      if (this.filterValue) {
        const data: any = this.viewData.rows.slice();
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = this.filterValue.trim().toLowerCase();
      } else {
        this.clearFilter();
      }      
    }, 1000);
  }

  private updateTimer: any;
  updateWithTimer(minutes: number) {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
    this.updateTimer = setTimeout(() => {
      this.refresh();
      this.updateWithTimer(minutes);
    }, 1000 * minutes);
  }

  public clearFilter() {
    this.filterValue = '';
    if (this.groupBy) {
      this.dataSource = new MatTableDataSource(this.dataArray);
    }

    this.dataSource.filter = '';
  }

  public linkedPageClicked(link: ILinkedPages, row: any, columnId: string) {
      const params: any = {};
      if (link.dashboard) {
        if (link.addToParam) {
          const data = row[columnId];
          params[link.addToParam] = data.toString();
        }
        this.router.navigate([`/dashboard/${link.dashboard}`], { queryParams: params });
      } else if (link.externalUrl) {
        let finalLink = link.externalUrl;        
        if (link.addToParam) {
          const data = row[columnId];
          finalLink = finalLink.replace(`@${link.addToParam}`, data.toString());
        }
        window.open(finalLink, '_blank');
      } else if (link.linkedForm) {
        const id = row[link.linkedForm.primaryKey];
        if (id) {
          this.formData = { 
            name: link.label,
            rowId: id,
            table: link.linkedForm!.tableEndpoint
          };
        }
      }
  }

  public sortData(sort: Sort) {
    let sortedData;
    const data: any = this.viewData.rows.slice();
    if (!sort.active || sort.direction === '') {
      sortedData = data;
    } else {
      sortedData = data.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'position': return this.compareNumber(a.position, b.position, isAsc);
          case 'name': return this.compareString(a.name, b.name, isAsc);
          case 'description': return this.compareString(a.description, b.description, isAsc);
          default: return 0;
        }
      });
    }

    this.dataSource = new MatTableDataSource(sortedData);
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filterValue;
  }

  compareNumber(a: number, b: number, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareString(a: string, b: string, isAsc: boolean) {
    return a.localeCompare(b, 'sv-SE') * (isAsc ? 1 : -1);
  }

  getChoiceLabel(value: any, column: ITableColumn): string {
    return column.possibleValues.find((v) => v.value === value)?.label ?? "";
  }  

  public copyToClipboard() {
    const str = TableDataToClipboardFormat(this.dataSource.filteredData, this.displayedColumns);
    navigator.clipboard.writeText(str);
  }

  public openWidgetSettings() {
    this.showWidgetClicked.emit();
  }

  rowTriggered(row: any) {
    if (this.parentHandlesRowTriggered) {
      this.onRowTriggered.emit(row);
    } else {
      this.openForm(row);
    }
  }

  openForm(row: any) {
    if (this.formEndpoint && this.viewData.primaryKey) {
      const id = row[this.viewData.primaryKey];
      if (id) {
        if (this.formEndpoint === 'errorreports') {
          this.errorReportId = id;
        } else {        
          this.formData = { 
            name: this.viewData.name,
            rowId: id,
            table: this.formEndpoint
          };
        }
      }
    }
  }

  newRecordForm() {
    if (this.parentHandlesAdd) {
      this.onAdd.emit();
    } else if (this.formEndpoint && this.formAllowAdd) {
      this.formData = { 
        name: this.viewData.name,
        rowId: 0,
        table: this.formEndpoint
      };
    }
  }

  formClosing(e: any) {
    if (e) {
      this.refresh();
    }

    this.formData = undefined;
  }
}
